import * as React from "react";
import { Modal, Button, Box, Typography } from "@mui/material";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "12px",
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  body: string;
};
export const InfoModal = (props: Props) => {
  const { open, onClose } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
        </div>
        <div className="flex justify-center mb-8">
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            {props.body}
          </Typography>
        </div>
        <div className="flex justify-center">
          <Button variant="contained" onClick={onClose}>
            Done
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
