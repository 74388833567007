import { useState, useEffect } from "react";
import { ThemeProvider, useRedirect } from "react-admin";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import { UserRequest } from "../../api/user";
import { InfoModal } from "../../component/modal";
import { validateEmail } from "../../helper/string";

export const PasswordRecovery = (props: any) => {
  const redirect = useRedirect();
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [infoModal, setInfoModal] = useState(false);

  const isFormNotFilled = () => (!email || !validateEmail(email) ? true : false);

  const SubmitButton = () => {
    const payload = { email };
    const submit = () => {
      UserRequest.requestChangePassword(payload)
        .then((r) => {
          setUserId(r.userId as string);
          setInfoModal(true);
          return;
        })
        .catch((e) => {
          console.log("ERROR: " + e);
        });
    };

    return (
      <Button
        fullWidth
        variant="contained"
        size="medium"
        onClick={submit}
        sx={{ mt: 3, mb: 2 }}
        disabled={isFormNotFilled()}
      >
        Send
      </Button>
    );
  };

  const submitRedirect = () => {
    setInfoModal(false);
    redirect(`/new-password?userId=${userId}&email=${email}`);
  };

  return (
    <ThemeProvider theme={props.theme}>
      <Container component="main" maxWidth="xs" className="h-screen bg-transparent">
        <CssBaseline />
        <div className="h-full flex items-center">
          <div>
            <Card
              className="w-[425px] rounded-lg bg-white mb-40"
              sx={{
                borderRadius: "33px",
                padding: "14px",
              }}
            >
              <CardContent>
                <Typography component="h2" variant="h5">
                  Password recovery!
                </Typography>
                <Typography component="p" variant="body1" color="gray">
                  A link with password recovery will be sent to your email.
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label="Email Address"
                    type="email"
                    id="email"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <SubmitButton />
              </CardContent>
            </Card>
          </div>
        </div>
        <InfoModal
          title="Success"
          body="Password reset request sent to your email."
          open={infoModal}
          onClose={submitRedirect}
        />
      </Container>
    </ThemeProvider>
  );
};

export const SetNewPassword = (props: any) => {
  const redirect = useRedirect();
  const params = new URL(document.location as unknown as string).searchParams;
  const userId = params.get("userId");
  const email = params.get("email") as string;
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [otp, setOtp] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const [noRequest, setNoRequest] = useState(true);
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const ResendOtpButton = () => {
    const payload = { email };
    const timeout = 3600;
    const submit = () => {
      UserRequest.requestChangePassword(payload)
        .then((r) => {
          setNoRequest(false);
          setCounter(60);
          return;
        })
        .catch((e) => {
          console.log("ERROR: " + e);
        });
    };

    return (
      <Button
        fullWidth
        type="button"
        variant="text"
        size="small"
        onClick={submit}
        sx={{ mt: 3, mb: 2, textTransform: "none" }}
        disabled={!noRequest && counter > 0}
      >
        <>Resend OTP {!noRequest && counter > 0 ? `(${counter})` : null}</>
      </Button>
    );
  };

  const isFormNotFilled = () =>
    !password1 || !password2 || password1 !== password2 || !otp ? true : false;

  const SubmitButton = () => {
    const payload = {
      newPassword: password1,
      otp,
      id: Number(userId),
    };
    const submit = () => {
      UserRequest.passwordReset(payload)
        .then(() => {
          setInfoModal(true);
          return;
        })
        .catch((e) => {
          console.log("ERROR: " + e);
        });
    };

    return (
      <Button
        fullWidth
        variant="contained"
        size="medium"
        onClick={submit}
        sx={{ mt: 3, mb: 2 }}
        disabled={isFormNotFilled()}
      >
        Send
      </Button>
    );
  };

  const submitRedirect = () => {
    setInfoModal(false);
    redirect("/login");
  };

  return (
    <ThemeProvider theme={props.theme}>
      <Container component="main" maxWidth="xs" className="h-screen bg-transparent">
        <CssBaseline />
        <div className="h-full flex items-center">
          <div>
            <Card
              className="w-[425px] rounded-lg bg-white mb-40"
              sx={{
                borderRadius: "33px",
                padding: "14px",
              }}
            >
              <CardContent>
                <Typography component="h2" variant="h5">
                  Password recovery!
                </Typography>
                <Typography component="p" variant="body1" color="gray">
                  Enter new password
                </Typography>
                <Box component="form" noValidate>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passowrd1"
                    label="New password"
                    type="password"
                    id="password1"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passowrd2"
                    label="Comfirmed new password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    type="text"
                    id="otp"
                    variant="outlined"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <ResendOtpButton />
                  <SubmitButton />
                </Box>
              </CardContent>
            </Card>
          </div>
        </div>
        <InfoModal
          title="Success"
          body="Successfully created a new passsword."
          open={infoModal}
          onClose={submitRedirect}
        />
      </Container>
    </ThemeProvider>
  );
};
