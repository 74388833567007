import { instance } from "../helper/api";
import { PostInterface, PostRequestInterface } from "../type/post";

export const PostRequest: PostRequestInterface = {
  deletePost: async (params: PostInterface) => {
    const id = params.id;
    delete params.id;
    const r = await instance.put(`post/delete/${id}`, params);
    return await Promise.resolve(r.data);
  },
};
