import { ResourceLabel } from "../label/resource";

export const ContentHeader = (props: any) => {
  const { subTitle } = props;
  return (
    <div className="grid justify-items-left my-6">
      <ResourceLabel subTitle={subTitle} />
    </div>
  );
};
