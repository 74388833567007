import jwt from "jwt-decode";
import { UserTokenInterface } from "../type/user";

export const getToken = () => {
  return localStorage.getItem("token");
};

export const setToken = (token: string) => {
  return localStorage.setItem("token", token);
};

export const decodeToken = (token: string): UserTokenInterface => {
  return jwt(token);
};
