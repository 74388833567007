import { instance, setQuery } from "../helper/api";

import { ApiParamsType } from "../type/api";

export const CommonRequest = {
  getList: async (resource: string, params: ApiParamsType) => {
    const query = setQuery(params);
    console.log("query", query);

    let url = "";
    switch (resource) {
      case "clubs":
        url = "admin/clubs/getAll";
        break;
      case "groups":
        url = "group";
        break;
      case "users":
        url = "/admin/users/getAll";
        break;
      case "events":
        url = "admin/events/getAll";
        break;
      case "bannedUsers":
        url = "/bannedlist";
        break;
      default:
        url = resource;
    }

    const r = await instance.get(url, { params: query });

    console.log(`getList ========== ${resource}`, r.data.data);
    // if params has filter only by createdBy
    if (r.data.data && r.data.data.length && params.filter?.createdBy) {
      r.data.data = r.data.data.filter((f: any) => {
        return f.createdBy === params.filter?.createdBy;
      });
      console.log("filtered", r.data.data);
    }
    // remove all items that is isDeleted=true
    // if (resource !== "users" && r.data.data && r.data.data.length) {
    //   console.log("HERE 2");
    //   r.data.data = r.data.data.filter((f: any) => f.isDeleted !== true);
    // }
    // remove all superAdmin
    if (r.data.data && r.data.data.length) {
      r.data.data = r.data.data.filter((f: any) => {
        if (f.role) {
          return f.role !== "superAdmin";
        }
        return true;
      });
    }
    r.data.total = r.data.total || r.data.length;
    return await Promise.resolve(r.data);
  },
  getOne: async (resource: string, params: ApiParamsType) => {
    const r = await instance.get(`${resource}/${params.id}`);
    console.log(`getOne ========== ${resource}`, r.data.data);
    return await Promise.resolve(r.data);
  },
  getMany: async (resource: string, params: any) => {
    const r = await instance.get(`${resource}/${params.ids[0]}`);
    return await Promise.resolve(r.data);
  },
  getManyReference: (resource: string, params: ApiParamsType) => instance.get(resource, params),
  update: async (resource: string, params: ApiParamsType) => {
    const id = params.id;
    const { data } = params;
    if (!data.members) data.members = [];
    data.imageUrl = "https://via.placeholder.com/150";
    const payload = {
      title: data.title,
      bio: data.bio,
      tags: data.tags || [],
      members: data.members || [],
      email: data.email,
      insta: data.insta,
      tiktok: data.tiktok,
      linkedin: data.linkedin,
      imageUrl: data.imageUrl || "https://via.placeholder.com/150",
      type: data.type,
    };
    console.log("data", data);
    const r = await instance.put(`${resource}/${id}`, payload);
    return await Promise.resolve(r.data);
  },
  updateMany: async (resource: string, params: ApiParamsType) => {
    const r = await instance.put(resource, params);
    return await Promise.resolve(r.data);
  },
  delete: async (resource: string, params: ApiParamsType) => {
    console.log("delete", resource, params);
    const r = await instance.delete(`${resource}/${params.id}`, params);
    return await Promise.resolve(r);
  },
  deleteMany: async (resource: string, params: ApiParamsType) => {
    console.log("deleteMany", params);
    await instance.delete(`${resource}/bulkDelete`, { data: params });
    return await Promise.resolve({ data: params.ids });
  },
  create: async (resource: string, params: ApiParamsType) => {
    console.log("create " + resource, params);
    const r = await instance.post(`${resource}${resource === "events" && "/add"}`, params);
    return await Promise.resolve(r);
  },
  uploadImage: async (params: any) => {
    const formData = new FormData();
    formData.append("image", params.rawFile);
    const r = await instance.put("/image/uploadImages", formData);
    return await Promise.resolve(r);
  },
};
