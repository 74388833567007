import { AppBar, AppBarProps, UserMenu, UserMenuProps } from "react-admin";

import { Box } from "@mui/material";
import { MainLogo } from "./logo/main";

const style = {
  boxShadow: "none",
  height: "48px",
  display: "flex",
  justifyContent: "center",
  fontFamily: "SFPro",
};

const MyUserMenu = (props: UserMenuProps) => <UserMenu {...props} />;

export const CustomAppBar = (props: AppBarProps | undefined) => (
  <AppBar
    sx={{
      "& .RaAppBar-title": {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      backgroundImage:
        localStorage.getItem("slug") === "chapman"
          ? "#A70032"
          : "-webkit-linear-gradient(180deg, #70ccd2 0%, #409de2 100%) !important",
      flexDirection: "column",
      bgcolor:
        localStorage.getItem("slug") === "chapman"
          ? "#A70032"
          : "-webkit-linear-gradient(180deg, #70ccd2 0%, #409de2 100%) !important",
    }}
    {...props}
    userMenu={<MyUserMenu />}
    style={style}>
    <MainLogo text="OFFLINE" />
    <Box flex="1" />
  </AppBar>
);
