import { useState, ChangeEvent, MouseEvent } from "react";
import {
  Modal,
  OutlinedInput,
  Button,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "12px",
  p: 4,
};

type Props = {
  open: boolean;
  onClose?: () => void;
  report: any;
  title: string;
  hasCategories: boolean;
  children: any;
};

export const ReportModal = (props: Props) => {
  const { open, onClose, report, title, hasCategories, children } = props;
  const [categories, setCategories] = useState([]);
  const [textReason, setTextReason] = useState("");

  const handleChange = (event: MouseEvent<HTMLElement>, chosenCategories: []) => {
    report({ categories: chosenCategories, textReason });
    setCategories(chosenCategories);
  };

  const textReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    report({ categories, textReason: event.target.value });
    setTextReason(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Action: {title}
          </Typography>
        </div>
        {hasCategories ? (
          <div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Choose how:
            </Typography>
            <ToggleButtonGroup
              value={categories}
              onChange={handleChange}
              aria-label="categories"
              size="small"
            >
              <ToggleButton value="hate speech">Hate Speech</ToggleButton>
              <ToggleButton value="bullying">Bullying</ToggleButton>
              <ToggleButton value="threat">Threat</ToggleButton>
            </ToggleButtonGroup>
          </div>
        ) : null}
        <div className="mb-8">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please write additional details:
          </Typography>
          <OutlinedInput
            multiline
            fullWidth
            minRows={5}
            value={textReason}
            onChange={textReasonChange}
          />
        </div>
        <div className="flex justify-center">{children}</div>
      </Box>
    </Modal>
  );
};
