export const Sheet = (props: any) => {
  const columns = ["Name", "Email", "Type"];
  const style = {
    height: "60px",
    background: "rgb(255, 255, 255)",
    border: "1px solid #DDD",
  };
  return (
    <table className="w-full mb-4">
      <thead>
        <tr>
          {columns.map((colName, index) => (
            <th key={index} style={style}>
              {colName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );
};
