import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  ReferenceInput,
  RowClickFunction,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Box, Button, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { BulkActionButtons, DropdownButtons, FilterButton } from "../../component/button";
import {
  DeleteOutline,
  DoNotDisturbAlt,
  EditOutlined,
  ErrorOutline,
  PlayCircleOutline,
} from "@mui/icons-material";
import { MouseEvent, useState } from "react";

import Chip from "@mui/material/Chip";
import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { useMutation } from "react-query";

const CustomEmpty = () => <div>No Events found</div>;

const filters = [
  <TextInput
    key="search"
    source="q"
    label="Search"
    alwaysOn
    variant="outlined"
    size="small"
    resettable
  />,
  <ReferenceInput key="status" source="isActive" label="Status" reference="events">
    <AutocompleteInput
      source="isActive"
      choices={[
        { id: false, name: "Inactive" },
        { id: true, name: "Active" },
      ]}
      label="Status"
      variant="outlined"
      size="small"
    />
  </ReferenceInput>,
];

type Record = {
  id: number;
  title: string;
  description: string;
  isDeleted: boolean;
  isActive: boolean;
  isHidden: boolean;
  createdAt: any;
  startDate: any;
  location: string;
  school: any;
};

export const EventList = () => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentResource, setCurrentResource] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<RaRecord>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAction, setCurrentAction] = useState<string>("");
  const openMoreActions = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    console.log("delete");
    dataProvider.deleteMany(currentResource, { ids: [currentId] });
    handleClose();
    refresh();
  };

  const hanldeEditMenu = () => {
    console.log("edit", currentId.toString());
    redirect(currentId.toString());
    handleClose();
  };

  const renderLabel = (record: Record) => {
    const dt = DateTime.fromISO(record.startDate);
    if (record.isDeleted === true) return "Deleted";
    if (record.isDeleted === false && dt < DateTime.now()) return "Elapsed";
    if (record.isDeleted === false) return "Active";
    if (record.isHidden === true) return "Inactive";
  };

  const renderStatusColor = (record: Record) => {
    const dt = DateTime.fromISO(record.startDate);
    if (record.isDeleted === true) return "error";
    if (record.isDeleted === false && dt < DateTime.now()) return "default";
    if (record.isDeleted === false) return "success";
    if (record.isHidden === true) return "warning";
  };

  const renderEventName = (record: Record) => {
    return record.isDeleted ? (
      <TextField source="title" sx={{ padding: "6px 12px" }} />
    ) : (
      <ShowButton
        label={record.title}
        icon={<></>}
        sx={{ textAlign: "left", textTransform: "none" }}
      />
    );
  };

  const setCurrentRow: RowClickFunction = (
    id: Identifier,
    resource: string,
    record: RaRecord,
  ): string => {
    setCurrentId(id);
    setCurrentResource(resource);
    setCurrentRecord(record);
    return "";
  };

  const filters = [
    <TextInput source="q" label="Search" alwaysOn variant="outlined" size="small" resettable />,
    <ReferenceInput key="status" source="isActive" label="Status" reference="clubs">
      <AutocompleteInput
        source="isActive"
        choices={[
          { id: false, name: "Inactive" },
          { id: true, name: "Active" },
        ]}
        label="Status"
        variant="outlined"
        size="small"
      />
    </ReferenceInput>,
  ];

  const ActionButtons = () => {
    const isDeleted = currentRecord?.isDeleted;

    return (
      <>
        {isDeleted === false && (
          <>
            <MenuItem onClick={handleDeleteMenu} dense>
              <ListItemIcon>
                <DeleteOutline fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={hanldeEditMenu} dense>
              <ListItemIcon>
                <EditOutlined fontSize="small" color="disabled" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </>
        )}
        {isDeleted === true && (
          <MenuItem dense>
            <ListItemIcon>
              <DeleteOutline fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText>No available actions</ListItemText>
          </MenuItem>
        )}
      </>
    );
  };

  return (
    <>
      <ContentHeader />
      <List
        sort={{ field: "createdAt", order: "DESC" }}
        filters={filters}
        exporter={false}
        hasCreate={true}>
        <Datagrid
          bulkActionButtons={<BulkActionButtons />}
          empty={<CustomEmpty />}
          sx={{
            "& .column-title": { width: "200px" },
            "& .column-createdAt": { width: "150px", textAlign: "right" },
          }}
          rowClick={setCurrentRow}>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            source="title"
            label="Event Name"
            render={(record: Record) => renderEventName(record)}
          />
          <FunctionField
            className="justify-center"
            textAlign="center"
            label="Status"
            render={(record: Record) => (
              <Chip label={renderLabel(record)} color={renderStatusColor(record)} size="small" />
            )}
          />
          <FunctionField
            label="Description"
            source="description"
            render={(record: Record) => record.description}
          />
          <FunctionField
            label="Location"
            source="location"
            render={(record: Record) => record.location}
          />
          <FunctionField
            label="Event Date"
            source="date"
            render={(record: Record) => {
              const dt = DateTime.fromISO(record.startDate);
              return (
                <div className="flex justify-start items-left">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                </div>
              );
            }}
          />
          <FunctionField
            label="Date Created"
            source="createdAt"
            render={(record: Record) => {
              const dt = DateTime.fromISO(record.createdAt);
              return (
                <div className="flex justify-end items-center">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                  <DropdownButtons
                    anchorEl={anchorEl}
                    open={openMoreActions}
                    onClose={handleClose}
                    onClick={handleClick}>
                    {localStorage.getItem("userRole") === "superAdmin" && <ActionButtons />}
                  </DropdownButtons>
                </div>
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
