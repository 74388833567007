import { Chip, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ClubEmptyFeedCard, PostFeedCard } from "../../component/card/feed";
import { DoNotDisturbAlt, FlagOutlined } from "@mui/icons-material";
import { List, useDelete, useGetList, useNotify, useRefresh } from "react-admin";
import { MouseEvent, useState } from "react";

import { ContentHeader } from "../../component/header/content";
import { DropdownActionButtons } from "../../component/button";

export const ReportList = () => {
  const [deleteOne] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const [currentId, setCurrentId] = useState<number>(0);
  const [reportedId, setReportedId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(anchorEl);

  const setQuery = () => {
    return {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "createdAt", order: "DESC" as const },
      filter: {},
    };
  };

  const { data: reports } = useGetList("report/admin/posts", setQuery());

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: number, reportedId: number) => {
    setReportedId(reportedId);
    setCurrentId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeep = () => {
    handleClose();
    deleteOne("report/admin/delete", { id: currentId });
    notify("Report deleted", { type: "success" });
    refresh();
  };

  const handleDelete = () => {
    handleClose();
    deleteOne("report/admin/delete", { id: currentId });
    deleteOne("grouppost", { id: reportedId });
    notify("Post deleted", { type: "success" });
  };

  return (
    <>
      <ContentHeader />
      <List
        emptyWhileLoading
        pagination={false}
        sx={{
          backgroundColor: "transparent",
          "& .RaList-content": {
            backgroundColor: "transparent",
          },
        }}>
        <div className="flex flex-col items-center">
          <div className="w-[600px] my-6">
            <span className="font-semibold">Action Required:</span>{" "}
            <Chip label={reports?.length} variant="filled" color="primary" size="small" />
          </div>
          {reports?.length === 0 ? <ClubEmptyFeedCard text="No Reported posts" /> : null}
          {reports?.map((post: any) => (
            <PostFeedCard
              key={post.id}
              id={post.id}
              userInfo={post.userInfo}
              createdAt={post.createdAt}
              content={post.content}
              reasons={post.comment}>
              <DropdownActionButtons
                anchorEl={anchorEl}
                open={openMoreActions}
                onClose={handleClose}
                onClick={(e) => handleClick(e, post.id, post.reportedId)}>
                <MenuItem onClick={() => handleKeep()} dense>
                  <ListItemIcon>
                    <FlagOutlined fontSize="small" color="disabled" />
                  </ListItemIcon>
                  <ListItemText>Keep</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleDelete()} dense>
                  <ListItemIcon>
                    <DoNotDisturbAlt fontSize="small" color="disabled" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </DropdownActionButtons>
            </PostFeedCard>
          ))}
        </div>
      </List>
    </>
  );
};
