import { AccessTime, PersonOutlineOutlined } from "@mui/icons-material";
import { Card, CardContent, Chip, Typography } from "@mui/material";

import { DateTime } from "luxon";

type ClubFeedProps = {
  title: string;
  createdAt: string;
  content: string;
  tags: string[];
  children: any;
};

export const ClubFeedCard = (props: ClubFeedProps) => {
  const { title, createdAt, content, tags, children } = props;
  const dt = DateTime.fromISO(createdAt);
  const date = dt.toLocaleString(DateTime.DATE_SHORT);
  const time = dt.toLocaleString(DateTime.TIME_SIMPLE);
  return (
    <Card
      className="w-[600px] rounded-lg mb-8"
      sx={{
        borderRadius: "33px",
        padding: "0 14px",
      }}>
      <CardContent>
        <div className="flex justify-between w-full mb-3">
          <div className="flex gap-4">
            <div className="flex items-center">
              <Typography>{date}</Typography>
            </div>
            <div className="flex items-center">
              <AccessTime sx={{ fontSize: "16px", marginRight: "2px" }} />
              <Typography>{time}</Typography>
            </div>
          </div>
          <div>{children}</div>
        </div>
        <Typography>Content:</Typography>
        <Typography>{content}</Typography>
        <div className="my-4">
          Tags:{" "}
          {tags?.length
            ? tags.map((t) => <Chip label={t} size="small" variant="outlined" className="mr-2" />)
            : null}
        </div>
      </CardContent>
    </Card>
  );
};

type PostFeedProps = {
  id: number;
  userInfo: { id: number; name: string };
  createdAt: string;
  content: string;
  reasons: string[];
  children: any;
};

export const PostFeedCard = (props: PostFeedProps) => {
  const { id, userInfo, createdAt, content, reasons, children } = props;
  const dt = DateTime.fromISO(createdAt);
  const date = dt.toLocaleString(DateTime.DATE_SHORT);
  const time = dt.toLocaleString(DateTime.TIME_SIMPLE);

  console.log("userInfo", userInfo);

  return (
    <Card
      className="w-[600px] rounded-lg mb-8"
      key={id}
      sx={{
        borderRadius: "33px",
        padding: "0 14px",
      }}>
      <CardContent>
        <div className="flex justify-between w-full">
          <div className="flex gap-4">
            <div className="flex items-center">
              <Typography>{date}</Typography>
            </div>
            <div className="flex items-center">
              <AccessTime sx={{ fontSize: "16px", marginRight: "2px" }} />
              <Typography>{time}</Typography>
            </div>
            {userInfo?.name ? (
              <div className="flex items-center">
                <PersonOutlineOutlined color="primary" sx={{ fontSize: "18px" }} />
                <Typography color="primary">{userInfo?.name}</Typography>
              </div>
            ) : null}
          </div>
          <div>{children}</div>
        </div>
        <Typography color="primary">Post:</Typography>
        <Typography>{content}</Typography>
        {reasons ? <Typography>Reasons: {reasons}</Typography> : null}
      </CardContent>
    </Card>
  );
};

type EventFeedProps = {
  key: number;
  createdAt: string;
  description: string;
  tags: string[];
  children: any;
  title: string;
};

export const EventFeedCard = (props: EventFeedProps) => {
  const { key, createdAt, title, description, tags, children } = props;
  const dt = DateTime.fromISO(createdAt);
  const date = dt.toLocaleString(DateTime.DATE_SHORT);
  const time = dt.toLocaleString(DateTime.TIME_SIMPLE);

  return (
    <Card
      className="w-[600px] rounded-lg mb-8"
      key={key}
      sx={{
        borderRadius: "33px",
        padding: "0 14px",
      }}>
      <CardContent>
        <div className="flex justify-between w-full">
          <div className="flex gap-4">
            <div className="flex items-center">
              <Typography>{date}</Typography>
            </div>
            <div className="flex items-center">
              <AccessTime sx={{ fontSize: "16px", marginRight: "2px" }} />
              <Typography>{time}</Typography>
            </div>
          </div>
          <div>{children}</div>
        </div>
        <Typography color="primary">Event: ${title}</Typography>
        <Typography>{description}</Typography>
        <div className="my-4">
          Tags:{" "}
          {tags?.length
            ? tags.map((t) => <Chip label={t} size="small" variant="outlined" className="mr-2" />)
            : null}
        </div>
      </CardContent>
    </Card>
  );
};

type EmptyFeedProps = {
  text: string;
};

export const ClubEmptyFeedCard = (props: EmptyFeedProps) => {
  const { text } = props;
  return (
    <Card
      className="w-full rounded-lg mb-8 flex justify-center items-center"
      sx={{
        borderRadius: "33px",
        padding: "0 14px",
        minHeight: "160px",
      }}>
      <Typography>{text}</Typography>
    </Card>
  );
};

type ProfileFeedProps = {
  id: number;
  reportedUser: { id: number; name: string };
  reporter: { id: number; name: string };
  createdAt: string;
  comment: string[];
  children: any;
};

export const ProfileFeedCard = (props: ProfileFeedProps) => {
  const { id, reportedUser, reporter, createdAt, comment, children } = props;
  const dt = DateTime.fromISO(createdAt);
  const date = dt.toLocaleString(DateTime.DATE_SHORT);
  const time = dt.toLocaleString(DateTime.TIME_SIMPLE);

  return (
    <Card
      className="w-[600px] rounded-lg mb-8"
      key={id}
      sx={{
        borderRadius: "33px",
        padding: "0 14px",
      }}>
      <CardContent>
        <div className="flex justify-between w-full">
          <div className="flex gap-4">
            <div className="flex items-center">
              <Typography>{date}</Typography>
            </div>
            <div className="flex items-center">
              <AccessTime sx={{ fontSize: "16px", marginRight: "2px" }} />
              <Typography>{time}</Typography>
            </div>
          </div>
          <div>{children}</div>
        </div>
        <Typography color="primary">Profile:</Typography>
        {reportedUser?.name ? (
          <div className="flex items-center">
            <PersonOutlineOutlined color="primary" sx={{ fontSize: "18px" }} />
            <Typography color="primary">{reportedUser?.name}</Typography>
          </div>
        ) : null}
        {comment ? <Typography>Reasons: {comment}</Typography> : null}
        <Typography>Reporter: {reporter?.name}</Typography>
      </CardContent>
    </Card>
  );
};

type GroupFeedProps = {
  id: number;
  reporter: { id: number; name: string };
  createdAt: string;
  comment: string[];
  children: any;
  groupId: number;
  groupName: string;
};

export const GroupFeedCard = (props: GroupFeedProps) => {
  const { id, reporter, groupId, groupName, createdAt, comment, children } = props;
  const dt = DateTime.fromISO(createdAt);
  const date = dt.toLocaleString(DateTime.DATE_SHORT);
  const time = dt.toLocaleString(DateTime.TIME_SIMPLE);

  return (
    <Card
      className="w-[600px] rounded-lg mb-8"
      key={id}
      sx={{
        borderRadius: "33px",
        padding: "0 14px",
      }}>
      <CardContent>
        <div className="flex justify-between w-full">
          <div className="flex gap-4">
            <div className="flex items-center">
              <Typography>{date}</Typography>
            </div>
            <div className="flex items-center">
              <AccessTime sx={{ fontSize: "16px", marginRight: "2px" }} />
              <Typography>{time}</Typography>
            </div>
          </div>
          <div>{children}</div>
        </div>
        <Typography color="primary">Group:</Typography>
        {groupName ? (
          <div className="flex items-center">
            <PersonOutlineOutlined color="primary" sx={{ fontSize: "18px" }} />
            <Typography color="primary">{groupName}</Typography>
          </div>
        ) : null}
        {comment ? <Typography>Reasons: {comment}</Typography> : null}
        <Typography>Reporter: {reporter?.name}</Typography>
      </CardContent>
    </Card>
  );
};
