import { Box, Button, Chip, Typography } from "@mui/material";
import { Cell, Sheet } from "../../component/grid";
import {
  Create,
  DateInput,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  TimeInput,
  required,
  useDataProvider,
  useNotify,
} from "react-admin";
import { SetStateAction, useState } from "react";

import CSVReader from "react-csv-reader";
import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { GridElement } from "../../type/grid";
import { HelpOutline } from "@mui/icons-material";
import { LightTooltip } from "../../component/tooltip";
import ReactDataSheet from "react-datasheet";
import { ToggleColorButton } from "../../component/button";
import { findIndex } from "lodash";
import { useMutation } from "react-query";
import { validateEmail } from "../../helper/string";

export const EventCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const transform = async (inputData: any) => {
    const { pictures } = inputData;
    const { data } = await dataProvider.uploadImage(pictures);
    const { success, url } = data;

    console.log("from form", inputData.startTime);
    const st = DateTime.fromJSDate(inputData.startTime);
    const et = DateTime.fromJSDate(inputData.endTime);
    console.log("converted", st);
    let payload;
    if (success) {
      payload = {
        title: inputData.title,
        description: inputData.description,
        imageUrl: [url],
        rsvpLink: inputData.rsvpLink,
        webLink: inputData.webLink,
        location: inputData.location,
        date: inputData.date,
        startTime: st.toFormat("HH:mm"),
        endTime: et.toFormat("HH:mm"),
      };
    }
    return {
      ...payload,
    };
  };

  const validateImage = [required()];
  const minDate = (value: string, allValues: string) => {
    if (!value) {
      return "The age is required";
    }
    return undefined;
  };
  const validateDate = [required(), minDate];
  return (
    <>
      <ContentHeader subTitle="Create an Event" />
      <div className="flex w-full mb-4">
        <div className="d-flex flex-column justify-start w-full">
          <Create transform={transform}>
            <SimpleForm>
              <ImageField source="imageUrl" title="title" />
              <ImageInput
                source="pictures"
                label="Club Image"
                validate={validateImage}
                multiple={false}
                accept="image/*"
                maxSize={10000000} //10MB
                sx={{
                  "& .RaFileInput-dropZone": {
                    backgroundColor: "gray",
                    width: "65%",
                  },
                  "& .RaLabeled-label": {
                    marginLeft: "2px",
                    fontSize: "15px",
                  },
                }}>
                <ImageField source="src" title="title" />
              </ImageInput>
              <TextInput label="Name" source="title" resettable required sx={{ width: "45%" }} />
              <TextInput
                label="Description"
                multiline
                resettable
                source="description"
                required
                sx={{ width: "65%" }}
              />
              <TextInput source="location" resettable required sx={{ width: "45%" }} />
              <div className="flex justify-between" style={{ width: "35%" }}>
                <DateInput source="date" validate={validateDate} />
                <TimeInput label="Time - Start" source="startTime" validate={required()} />
                <TimeInput label="Time - End" source="endTime" validate={required()} />
              </div>
              <TextInput
                label="Website Link"
                source="webLink"
                type="url"
                resettable
                sx={{ width: "45%" }}
              />
              <TextInput
                label="RSVP Link"
                source="rsvpLink"
                type="url"
                resettable
                sx={{ width: "45%" }}
              />
            </SimpleForm>
          </Create>
        </div>
      </div>
    </>
  );
};
