import ReactDataSheet from "react-datasheet";
import { GridElement } from "../../type/grid";
export const Cell: ReactDataSheet.CellRenderer<GridElement, string> = (props: any) => {
  const style = {
    textAlign: "center" as const,
    height: "60px",
    width: "33%",
    background: "rgb(255, 255, 255)",
    border: "1px solid #DDD",
  };
  return (
    <td
      onMouseDown={props.onMouseDown}
      onMouseOver={props.onMouseOver}
      onClick={props.onDoubleClick}
      style={style}
    >
      {props.children}
    </td>
  );
};
