// declare const google: any;

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useLogin, useNotify, useRedirect } from "react-admin";

import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";
// import jwt_decode from "jwt-decode";
import { useMutation } from "react-query";
import { useState } from "react";
import { validateEmail } from "../../helper/string";

const theme = createTheme({
  typography: {
    h3: {
      fontFamily: "SFPro",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundImage: "-webkit-linear-gradient(180deg, #409de2 0%, #70ccd2 100%)",
    },
  },
});

export const Login = () => {
  const login = useLogin();
  const notify = useNotify();
  // const authProvider = useAuthProvider();
  const redirect = useRedirect();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const handleGoogleCallback = (res: any) => {
  //   const user = jwt_decode(res.credential) as Record<string, unknown>;
  //   console.log("google res user", user);
  //   authProvider
  //     .Ssologin({ email: user.email })
  //     .then(() => {
  //       redirect("/");
  //     })
  //     .catch((e: any) => {
  //       console.log(`Error: ${e}`);
  //     });
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   /*global google */
  //   google.accounts.id.initialize({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     callback: handleGoogleCallback,
  //   });

  //   google.accounts.id.renderButton(document.getElementById("signInDiv"), {
  //     theme: "outline",
  //     size: "large",
  //     width: "365",
  //   });

  //   google.accounts.id.prompt();
  // }, []);

  const isFormNotFilled = () => (!email || !validateEmail(email) || !password ? true : false);

  const SubmitButton = () => {
    const payload = {
      email,
      password,
    };
    const { mutate, isSuccess, isError, error } = useMutation(["login", payload], async () => {
      const result = await login(payload);
      return result;
    });
    if (isError) {
      const { response } = error as any;
      notify(response?.data?.message || error, { type: "error" });
    }

    if (isSuccess) {
      redirect("/");
    }

    return (
      <Button
        fullWidth
        variant="contained"
        size="medium"
        sx={{
          background: "-webkit-linear-gradient(180deg, #409de2 0%, #70ccd2 100%)",
          mt: 3,
          mb: 2,
        }}
        onClick={() => mutate()}
        disabled={isFormNotFilled()}>
        Log in
      </Button>
    );
  };

  return (
    <Container component="main" maxWidth="xs" className="h-screen bg-transparent">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className="h-full flex items-center">
          <div>
            <div className="d-flex flex-column mt-20 mb-5">
              <Typography component="h3" variant="h3" align="center">
                OFFLINE
              </Typography>
              <Typography component="h5" variant="h5" align="center">
                Administration
              </Typography>
            </div>
            <div className="mb-40">
              <Card
                className="w-[425px] rounded-lg bg-white"
                sx={{
                  borderRadius: "33px",
                  padding: "14px",
                }}>
                <CardContent>
                  <Typography component="p" variant="body1" color="gray">
                    Log in to your account to control and monitor your community.
                  </Typography>
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      InputProps={{
                        sx: {
                          "&:focus-within fieldset, &:focus-visible fieldset": {
                            border: "2px solid #409de2 !important",
                          },
                        },
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      InputProps={{
                        sx: {
                          "&:focus-within fieldset, &:focus-visible fieldset": {
                            border: "2px solid #409de2 !important",
                          },
                        },
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Grid container>
                      <Grid item xs>
                        <Link to="/password-recovery">Forgot password?</Link>
                      </Grid>
                    </Grid>
                    <SubmitButton />
                    {/*<div className="flex justify-center mb-3">OR</div>
                  <GoogleButton />*/}
                  </Box>
                </CardContent>
              </Card>
              {/* <Grid container justifyContent="center" color="grey">
              <Grid item>
                <Link to="/register">{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid> */}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Container>
  );
};
