import { Chip, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ClubEmptyFeedCard, ClubFeedCard, GroupProfileCard } from "../../component/card";
import { DeleteOutline, DoNotDisturbAlt } from "@mui/icons-material";
import {
  FunctionField,
  Identifier,
  ImageField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  useDataProvider,
  useDelete,
  useGetMany,
  useNotify,
  useRecordContext,
} from "react-admin";
import { MouseEvent, useState } from "react";

import { ContentHeader } from "../../component/header/content";
import { DropdownActionButtons } from "../../component/button";

export const GroupShow = () => {
  const [deleteOne] = useDelete();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [posts, setPosts] = useState<any[] | undefined>([]);
  const [events, setEvents] = useState<any[] | undefined>([]);
  const [groups, setGroups] = useState<any[] | undefined>([]);
  const [pauseModalOpen, setPauseModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [pauseComment, setPauseComment] = useState<string>("");
  const openMoreActions = Boolean(anchorEl);

  const GetData = () => {
    const record = useRecordContext();
    const { data: resultGroups } = useGetMany("group", { ids: [record.id] });
    setGroups(resultGroups);
    console.log("groups", groups);
    return null;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = (id: number, type: string) => {
    deleteOne(type, { id });
    handleClose();
  };

  const GroupStatus = () => (
    <FunctionField
      label="status"
      render={(record: { isActive: boolean }) =>
        record.isActive !== null ? (
          <Chip
            label={record.isActive === true ? "Active" : "Deleted"}
            color={record.isActive === true ? "success" : "default"}
            size="small"
          />
        ) : null
      }
    />
  );

  const MenuItems = ({ id, type }: { id: number; type: string }) => (
    <>
      <MenuItem onClick={() => handleDeleteMenu(id, type)} dense>
        <ListItemIcon>
          <DeleteOutline fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </>
  );

  return (
    <>
      <ContentHeader />
      <Show
        emptyWhileLoading
        actions={false}
        sx={{
          "& .RaShow-card": {
            border: "none",
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}>
        <GetData />
        <div className="grid md:grid-cols-4">
          <div className="sm:col-span-4 md:col-span-1">
            <GroupProfileCard
              title={<TextField source="title" sx={{ fontSize: "24px" }} />}
              image={
                <ImageField
                  source="imageUrl"
                  title="profile"
                  sx={{
                    "& .RaImageField-image": {
                      width: "94px",
                      height: "94px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      margin: "auto",
                      background: "#A02727",
                    },
                  }}
                />
              }
              description="test"
              status={<GroupStatus />}
              hasActions={true}
            />
          </div>

          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2">
            <SimpleShowLayout>
              <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="fullWidth" className="w-[600px]" />}>
                <Tab label="groups">
                  {groups?.length ? (
                    groups.map((p) => (
                      <ClubFeedCard
                        key={p.id}
                        title={p.title}
                        createdAt={p.createdAt}
                        content={p.content}
                        tags={p.tags}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={p.id} type="groups" />
                        </DropdownActionButtons>
                      </ClubFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Group found." />
                  )}
                </Tab>
              </TabbedShowLayout>
            </SimpleShowLayout>
          </div>
        </div>
      </Show>
    </>
  );
};
