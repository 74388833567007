import axios, { AxiosResponse } from "axios";

import { ApiParamsType } from "../type/api";

export const responseBody = (response: AxiosResponse) => response.data;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  timeout: 15000,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || "";
  const slug = localStorage.getItem("slug") || "bondo";
  const apiVersion = localStorage.getItem("api_version") || "1.0.0";

  if (config.headers) {
    config.headers["slug"] = slug;
    config.headers["x-api-version"] = apiVersion;
    config.headers["Authorization"] = token;
  }
  return config;
});

export const setQuery = (params: ApiParamsType) => {
  // transform a filter object to a filters array with operators
  // filter is like { commentable: true, released_gte: '2018-01-01' }
  const filter = params.filter || {};
  const operators = { _gte: ">=", _lte: "<=", _neq: "!=" } as any;
  // filters is like [
  //    { field: "commentable", operator: "=", value: true},
  //    { field: "released", operator: ">=", value: '2018-01-01'}
  // ]
  const filters = Object.keys(filter).map((key) => {
    const operator = operators[key.slice(-4)];
    return operator
      ? { field: key.slice(0, -4), operator, value: filter[key] }
      : { field: key, operator: "=", value: filter[key] };
  });
  return {
    pagination: params.pagination,
    sort: params.sort,
    filter: filters,
  };
};
