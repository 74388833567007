import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const style = {
  // fontFamily: "Pacifico",
  alignItems: "center",
  fontSize: 50,
};

export const Dashboard = () => {
  return (
    <>
      <div className="h-full flex flex-col items-center">
        <Card style={{ alignItems: "center", fontSize: 50 }}>
          <CardContent>Welcome to OFFLINE Administration</CardContent>
        </Card>
      </div>
    </>
  );
};
