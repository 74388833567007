import { Box, Modal, Typography } from "@mui/material";
import { ChangeEvent, MouseEvent, useState } from "react";
import { Datagrid, List, TextField, TextInput } from "react-admin";

import { ClubEmptyFeedCard } from "../../component/card";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "12px",
  p: 4,
};

type Props = {
  open: boolean;
  onClose?: () => void;
  //   email: (email: string) => void;
  children: any;
};

export const AddMemberModal = (props: Props) => {
  const { open, onClose, children } = props;
  const filters = [
    <TextInput source="name" label="Search" alwaysOn variant="outlined" size="small" />,
  ];

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="flex justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Member
          </Typography>
        </div>
        <div className="mb-8">
          <List
            resource={`users`}
            storeKey="clubMembers"
            exporter={false}
            hasCreate={false}
            filters={filters}
            empty={<ClubEmptyFeedCard text="No members founds" />}>
            <Datagrid>
              <TextField
                className="flex justify-start"
                textAlign="left"
                label="Name"
                source="name"
              />
            </Datagrid>
          </List>
        </div>
        <div className="flex justify-center">{children}</div>
      </Box>
    </Modal>
  );
};
