import { BulkActionButtons, DropdownButtons } from "../../component/button";
import {
  CreateBase,
  CreateButton,
  Datagrid,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  RowClickFunction,
  SimpleForm,
  TextInput,
  Title,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";

import { Card } from "@mui/material";
import Chip from "@mui/material/Chip";
import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { DeleteOutline } from "@mui/icons-material";

const CustomEmpty = () => <div>No Users found</div>;

type Record = {
  email: string;
  description: string;
  isDeleted: boolean;
  status: string;
};

export const Create = () => (
  <CreateBase>
    <div>
      <Title title="Add to Banned Users" />
      <Card>
        <SimpleForm>
          <TextInput source="email" />
          <TextInput source="description" />
        </SimpleForm>
      </Card>
    </div>
  </CreateBase>
);

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="Add" />
  </TopToolbar>
);

export const BannedUserList = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentResource, setCurrentResource] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<RaRecord>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("currentId", currentId);

  console.log("currentRecord", currentRecord);

  console.log("currentResource", currentResource);

  const renderLabel = (record: Record) => {
    if (record.isDeleted === false) return "Banned";
  };

  const renderStatusColor = (record: Record) => {
    if (record.isDeleted === false) return "error";
  };

  const handleDeleteMenu = () => {
    dataProvider.unbanUser(currentId);
    handleClose();
    refresh();
  };

  const ActionButtons = () => {
    const isDeleted = currentRecord?.isDeleted;

    return (
      <>
        x
        {!isDeleted && (
          <MenuItem onClick={handleDeleteMenu} dense>
            <ListItemIcon>
              <DeleteOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>Unban User</ListItemText>
          </MenuItem>
        )}
      </>
    );
  };

  const setCurrentRow: RowClickFunction = (
    id: Identifier,
    resource: string,
    record: RaRecord,
  ): string => {
    setCurrentId(id);
    setCurrentResource(resource);
    setCurrentRecord(record);
    return "";
  };

  return (
    <>
      <ContentHeader />
      <List sort={{ field: "createdAt", order: "DESC" }} actions={<ListActions />}>
        <Datagrid
          bulkActionButtons={
            localStorage.getItem("userRole") === "superAdmin" && <BulkActionButtons />
          }
          empty={<CustomEmpty />}
          sx={{
            "& .column-email": { width: "300px" },
            "& .column-createdAt": { width: "200px", textAlign: "right" },
          }}
          rowClick={setCurrentRow}>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            source="email"
            label="Email"
            render={(record: { email: string }) => record.email}
          />
          <FunctionField
            className="justify-center"
            source="status"
            label="Status"
            render={(record: Record) => (
              <Chip label={renderLabel(record)} color={renderStatusColor(record)} size="small" />
            )}
          />
          <FunctionField
            label="Description"
            source="description"
            render={(record: { description: string }) => record.description}
          />
          <FunctionField
            label="Banned Date"
            source="createdAt"
            render={(record: { createdAt: string; isBlockDeleted: boolean }) => {
              const dt = DateTime.fromISO(record.createdAt);
              return (
                <div className="flex justify-end items-center">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                  <DropdownButtons
                    anchorEl={anchorEl}
                    open={openMoreActions}
                    onClose={handleClose}
                    onClick={handleClick}>
                    <ActionButtons />
                  </DropdownButtons>
                </div>
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
