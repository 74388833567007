import {
  BannedUsersInterface,
  BlockUserInterface,
  UserEmailInterface,
  UserInterface,
  UserLoginInterface,
  UserRequestInterface,
} from "../type/user";
import { instance, responseBody } from "../helper/api";

export const UserRequest: UserRequestInterface = {
  login: (data: UserLoginInterface) =>
    instance.post<UserLoginInterface>("/admin/login", data).then(responseBody),
  Ssologin: (data: UserEmailInterface) =>
    instance.post<UserLoginInterface>("/validate/sso", data).then(responseBody),
  registerUser: async (params: UserInterface) => {
    const r = await instance.post("/users/add", params);
    return await Promise.resolve(r.data);
  },
  updateUser: async (params: UserInterface) => {
    const id = params.id;
    delete params.id;
    const r = await instance.put(`users/${id}`, params);
    return await Promise.resolve(r.data);
  },
  activateUser: async (params: UserInterface) => {
    const id = params.id;
    delete params.id;
    const r = await instance.put(`users/activateUser/${id}`, params);
    return await Promise.resolve(r.data);
  },
  requestChangePassword: async (params: UserInterface) => {
    try {
      const r = await instance.post(`users/forgotPassword`, params);
      return await Promise.resolve(r.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  passwordReset: async (params: UserInterface) => {
    try {
      const r = await instance.post(`users/customerPasswordReset`, params);
      return await Promise.resolve(r.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  blockUser: async (params: BlockUserInterface) => {
    const r = await instance.post("blockedusers/", params);
    return await Promise.resolve(r.data);
  },
  unblockUser: async (params: BlockUserInterface) => {
    const r = await instance.put(`blockedusers/delete/${params.blockedUserId}`, params);
    return await Promise.resolve(r.data);
  },
  banUser: async (params: BannedUsersInterface) => {
    const r = await instance.post("bannedlist/add", params);
    return await Promise.resolve(r.data);
  },
  unbanUser: async (id: number) => {
    const r = await instance.put(`bannedlist/delete/${id}`);
    return await Promise.resolve(r.data);
  },
};
