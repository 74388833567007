import { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  useNotify,
  useDataProvider,
  FunctionField,
  ImageField,
} from "react-admin";
import { useMutation } from "react-query";
import { Button } from "@mui/material";
import { ContentHeader } from "../../component/header/content";
import { ClubProfileEditCard } from "../../component/card";
import { UpdatePresidentModal } from "../../component/modal";
import { validateEmail } from "../../helper/string";

export const ClubEdit = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [presEmail, setPresEmail] = useState("");
  const [clubId, setClubId] = useState(0);
  const [presModalOpen, setPresModalOpen] = useState(false);

  const handleUpdatePresModal = (id: number) => {
    setClubId(id);
    setPresModalOpen(true);
  };

  const handleUpdatePresModalClose = () => {
    setPresModalOpen(false);
  };

  const handleSubmitEmail = (email: string) => {
    setPresEmail(email);
  };

  const UpdatePresidentButton = () => {
    const payload = { email: presEmail };
    const { mutate, isSuccess, isError, error } = useMutation(() => {
      const isValidEmail = validateEmail(presEmail);

      if (!isValidEmail) {
        return Promise.reject(`Email is not valid`);
      }
      return dataProvider.updatePresident(clubId, payload);
    });

    if (isSuccess) {
      const message = `Successfully updated a club's president`;
      notify(message, { type: "success" });
      handleUpdatePresModalClose();
    }

    if (isError) {
      console.log("error", error);
      const { response } = error as any;
      notify(response?.data?.message || error, { type: "error" });
      handleUpdatePresModalClose();
    }

    return (
      <Button variant="contained" onClick={() => mutate()}>
        Update
      </Button>
    );
  };

  return (
    <>
      <ContentHeader />
      <Edit
        actions={false}
        sx={{
          "& .RaEdit-card": {
            border: "none",
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}
      >
        <div>
          <div className="grid md:grid-cols-4">
            <div className="sm:col-span-4 md:col-span-1">
              <ClubProfileEditCard
                name={<TextField source="title" sx={{ fontSize: "24px" }} />}
                image={
                  <ImageField
                    source="imageUrl"
                    title="profile"
                    sx={{
                      "& .RaImageField-image": {
                        width: "94px",
                        height: "94px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        margin: "auto",
                        background: "#A02727",
                      },
                    }}
                  />
                }
              >
                <FunctionField
                  className="capitalize flex justify-center"
                  source="status"
                  label="Status"
                  render={(record: { id: number }) => (
                    <Button variant="contained" onClick={() => handleUpdatePresModal(record.id)}>
                      Update President
                    </Button>
                  )}
                />
              </ClubProfileEditCard>
            </div>
            <div className="sm:col-span-4 md:col-span-3 lg:col-span-2 px-[16px] py-[8px]">
              <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="fullWidth" />}>
                <Tab label="info">
                  <SimpleForm>
                    <TextInput disabled label="Id" source="id" variant="outlined" />
                    <TextInput
                      label="Name"
                      source="title"
                      validate={required()}
                      variant="outlined"
                    />
                    <TextInput disabled label="Email" source="email" variant="outlined" />
                    <TextInput
                      label="Type"
                      source="type"
                      validate={required()}
                      variant="outlined"
                    />
                    <TextInput label="Bio" source="bio" variant="outlined" validate={required()} />
                    <TextInput
                      label="Instagram"
                      source="insta"
                      variant="outlined"
                      validate={required()}
                    />
                    <TextInput
                      label="LinkedIn"
                      source="linkedin"
                      variant="outlined"
                      validate={required()}
                    />
                    <TextInput
                      label="Tiktok"
                      source="tiktok"
                      variant="outlined"
                      validate={required()}
                    />
                  </SimpleForm>
                </Tab>
              </TabbedShowLayout>
            </div>
          </div>
          <UpdatePresidentModal
            open={presModalOpen}
            onClose={handleUpdatePresModalClose}
            email={handleSubmitEmail}
            title="Update President"
          >
            <UpdatePresidentButton />
          </UpdatePresidentModal>
        </div>
      </Edit>
    </>
  );
};
