type Props = {
  title: string;
  text: string;
};
export const SideBarHeader = (props: Props) => (
  <div className="ml-6 mt-6">
    <span className="text-3xl tex-lg font-semibold">{props.title}</span>
    <br />
    <span className="text-m text-secondary tex-lg">{props.text}</span>
  </div>
);
