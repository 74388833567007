import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  ReferenceInput,
  RowClickFunction,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Box, Button, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { BulkActionButtons, DropdownButtons, FilterButton } from "../../component/button";
import { ClubFilterFormModal, PauseModal, ReportModal } from "../../component/modal";
import {
  DeleteOutline,
  DoNotDisturbAlt,
  EditOutlined,
  ErrorOutline,
  PlayCircleOutline,
} from "@mui/icons-material";
import { MouseEvent, useState } from "react";

import Chip from "@mui/material/Chip";
import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { useMutation } from "react-query";

const CustomEmpty = () => <div>No Clubs found</div>;

const filters = [
  <TextInput
    key="search"
    source="q"
    label="Search"
    alwaysOn
    variant="outlined"
    size="small"
    resettable
  />,
  <ReferenceInput key="status" source="isActive" label="Status" reference="clubs">
    <AutocompleteInput
      source="isActive"
      choices={[
        { id: false, name: "Inactive" },
        { id: true, name: "Active" },
      ]}
      label="Status"
      variant="outlined"
      size="small"
    />
  </ReferenceInput>,
];

type Record = {
  id: number;
  title: string;
  description: string;
  isDeleted: boolean;
  isActive: boolean;
  isHidden: boolean;
  clubPresident: string;
  createdAt: any;
  school: any;
};
type ModalResponseType = { categories: string[]; textReason: string };

export const ClubList = () => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentResource, setCurrentResource] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<RaRecord>();
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAction, setCurrentAction] = useState<string>("");
  const openMoreActions = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    dataProvider.deleteMany(currentResource, { ids: [currentId] });
    handleClose();
    refresh();
  };

  const hanldeEditMenu = () => {
    redirect(currentId.toString());
    handleClose();
  };

  const handleFilterButton = () => {
    setFilterModalOpen(true);
    console.log("filterModalOpen", filterModalOpen);
  };

  const handleSubmitFilter = (values: any) => {
    console.log("onSubmit", values);
    setFilterModalOpen(false);

    const params = {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "createdAt", order: "DESC" },
      filter: [],
    };

    const filter: any[] = [];

    if (values.isActive) {
      filter.push({ field: "isActive", value: values.isActive });
    }

    if (values.createdFrom) {
      filter.push({ field: "createdAt", operator: "_gte", value: values.createdFrom });
    }

    if (values.createdTo) {
      filter.push({ field: "createdAt", operator: "_lte", value: values.createdTo });
    }

    params.filter = filter as never[];
    if (setFilters) setFilters(params, []);
  };

  const renderLabel = (record: Record) => {
    if (record.isDeleted === true) return "Deleted";
    if (record.isDeleted === false) return "Active";
    if (record.isHidden === true) return "Inactive";
  };

  const renderStatusColor = (record: Record) => {
    if (record.isDeleted === true) return "error";
    if (record.isDeleted === false) return "success";
    if (record.isHidden === true) return "default";
  };

  const renderClubName = (record: Record) => {
    return record.isDeleted ? (
      <TextField
        source="title"
        sx={{
          textTransform: "none",
          padding: "6px",
        }}
      />
    ) : (
      <ShowButton
        label={record.title}
        icon={<></>}
        sx={{
          "&.MuiButtonBase-root": {
            padding: "0px",
          },

          textTransform: "none",
        }}
      />
    );
  };

  const setCurrentRow: RowClickFunction = (
    id: Identifier,
    resource: string,
    record: RaRecord,
  ): string => {
    setCurrentId(id);
    setCurrentResource(resource);
    setCurrentRecord(record);
    return "";
  };

  return (
    <>
      <ContentHeader />
      <List
        sort={{ field: "createdAt", order: "DESC" }}
        // filters={filters}
        exporter={false}
        hasCreate={true}>
        <Datagrid
          bulkActionButtons={<BulkActionButtons />}
          empty={<CustomEmpty />}
          sx={{
            "& .column-title": { width: "200px" },
            "& .column-createdAt": { width: "150px", textAlign: "right" },
          }}
          rowClick={setCurrentRow}>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            source="title"
            label="Club Name"
            render={(record: Record) => renderClubName(record)}
          />
          <FunctionField
            className="justify-center"
            textAlign="center"
            source="status"
            label="Status"
            render={(record: Record) => (
              <Chip label={renderLabel(record)} color={renderStatusColor(record)} size="small" />
            )}
          />
          <FunctionField
            label="Description"
            source="description"
            render={(record: Record) => record.description}
          />
          <FunctionField
            label="Club President"
            source="clubPresident"
            render={(record: Record) => record.clubPresident}
          />
          <FunctionField
            label="School"
            source="school"
            render={(record: Record) => record.school.shortName}
          />
          <FunctionField
            label="Date Created"
            source="createdAt"
            render={(record: Record) => {
              const dt = DateTime.fromISO(record.createdAt);
              return (
                <div className="flex justify-end items-center">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                  <DropdownButtons
                    anchorEl={anchorEl}
                    open={openMoreActions}
                    onClose={handleClose}
                    onClick={handleClick}>
                    {record.isDeleted ||
                      (localStorage.getItem("userRole") === "superAdmin" && (
                        <MenuItem onClick={handleDeleteMenu} dense>
                          <ListItemIcon>
                            <DeleteOutline fontSize="small" color="error" />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      ))}

                    {/* <MenuItem onClick={hanldeEditMenu} dense>
                      <ListItemIcon>
                        <EditOutlined fontSize="small" color="disabled" />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem> */}
                  </DropdownButtons>
                </div>
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
