import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { Form, ThemeProvider, useDataProvider, useGetList, useRedirect } from "react-admin";
import { useEffect, useState } from "react";

import { AutocompleteArrayInput } from "react-admin";
import { InfoModal } from "../../component/modal";

export const BroadCast = (props: any) => {
  const { data } = useGetList("schools", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });
  const [schoolId, setSchoolId] = useState("");
  const [message, setMessage] = useState("");
  const [infoModal, setInfoModal] = useState(false);

  const isFormNotFilled = () => (!schoolId || !message ? true : false);

  const SubmitButton = () => {
    const payload = { schoolId, message };
    const submit = () => {
      console.log("payload", payload);
    };

    return (
      <Button
        fullWidth
        variant="contained"
        size="medium"
        onClick={submit}
        sx={{ mt: 3, mb: 2 }}
        disabled={!message && !schoolId}>
        Send
      </Button>
    );
  };

  const submitRedirect = () => {
    setInfoModal(false);
  };

  return (
    <ThemeProvider theme={props.theme}>
      <Container component="main" maxWidth="xs" className="h-screen bg-transparent">
        <CssBaseline />
        <div className="h-full flex items-center">
          <div>
            <Card
              className="w-[425px] rounded-lg bg-white mb-40"
              sx={{
                borderRadius: "33px",
                padding: "14px",
              }}>
              <CardContent>
                <Typography component="h2" variant="h5">
                  Message Broadcast
                </Typography>
                <Typography component="p" variant="body1" color="gray">
                  Send a broadcast message to OFFLINE Community
                </Typography>
                <Form>
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    <AutocompleteArrayInput
                      source="schools"
                      parse={(value) => value && value.map((v: any) => ({ id: v }))}
                      format={(value) => value && value.map((v: any) => v.id)}
                      choices={data}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={8}
                      name="message"
                      label="Type your message here"
                      id="message"
                      variant="outlined"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Box>

                  <SubmitButton />
                </Form>
              </CardContent>
            </Card>
          </div>
        </div>
        <InfoModal
          title="Success"
          body="Message sent successfully"
          open={infoModal}
          onClose={submitRedirect}
        />
      </Container>
    </ThemeProvider>
  );
};
