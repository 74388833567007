import { useState, ChangeEvent, MouseEvent } from "react";
import {
  Modal,
  OutlinedInput,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "12px",
  p: 4,
};

type Props = {
  open: boolean;
  onClose?: () => void;
  pause: any;
  title: string;
  children: any;
};

export const PauseModal = (props: Props) => {
  const { open, onClose, pause, title, children } = props;

  const textReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    pause({ textReason: event.target.value });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Action: {title}
          </Typography>
        </div>
        <div className="mb-8">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please write additional details:
          </Typography>
          <OutlinedInput multiline fullWidth minRows={5} onChange={textReasonChange} />
        </div>
        <div className="flex justify-center">{children}</div>
      </Box>
    </Modal>
  );
};
