import { FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { InputProps, useInput } from "react-admin";

import { Fragment } from "react";

export const ToggleColorButton = (props: InputProps) => {
  const { field } = useInput(props);
  const groupColors = [
    "#3618A8",
    "#C41DFF",
    "#6EC267",
    "#034B20",
    "#4090C9",
    "#000000",
    "#731CA3",
    "#A70032",
    "#E9412B",
    "#EA602C",
    "#FFB702",
    "#ED9E38",
  ];

  return (
    <Fragment>
      <div className="d-flex flex-column w-75">
        <FormLabel id="toggle-group-color-button" className="ml-1">
          Color
        </FormLabel>
        <ToggleButtonGroup
          fullWidth={true}
          exclusive
          aria-label="color"
          {...field}
          sx={{
            letterSpacing: "0.25",
          }}>
          {groupColors.map((c, i) => (
            <ToggleButton
              value={c}
              aria-label={c}
              key={`${c}_${i}`}
              sx={{
                "&:hover": {
                  outlineColor: "#000",
                  outlineWidth: "5px",
                  outlineStyle: "double",
                  backgroundColor: c,
                },
                "&.Mui-selected": {
                  backgroundColor: c,
                  "&:hover": { backgroundColor: c },
                  outlineColor: "#000",
                  outlineWidth: "3px",
                  outlineStyle: "solid",
                },
                backgroundColor: c,
                height: "50px",
                width: "50px",
                marginRight: "10px",
                borderRadius: "0",
                border: "none",
              }}
            />
          ))}
        </ToggleButtonGroup>
      </div>
    </Fragment>
  );
};
