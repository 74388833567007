import { Add, DeleteOutline, PersonPin } from "@mui/icons-material";
import { Box, Chip, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import {
  Button,
  CreateButton,
  Datagrid,
  DatagridConfigurable,
  FunctionField,
  Identifier,
  ImageField,
  List,
  SelectColumnsButton,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TopToolbar,
  useDataProvider,
  useDelete,
  useDeleteMany,
  useGetList,
  useGetMany,
  useList,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import {
  ClubEmptyFeedCard,
  ClubFeedCard,
  ClubProfileCard,
  EventFeedCard,
} from "../../component/card";
import { MouseEvent, useState } from "react";

import { AddMemberModal } from "../../component/modal";
import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { DropdownActionButtons } from "../../component/button";
import { DropdownButtons } from "../../component/button";
import { Fragment } from "react";
import { useMutation } from "react-query";

export const ClubShow = () => {
  const [deleteOne] = useDelete();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const [posts, setPosts] = useState<any[] | undefined>([]);
  const [events, setEvents] = useState<any[] | undefined>([]);
  const [groups, setGroups] = useState<any[] | undefined>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentRecord, setCurrentRecord] = useState<any | undefined>(null);
  const openMoreActions = Boolean(anchorEl);

  const GetData = () => {
    const record = useRecordContext();
    setCurrentRecord(record);
    //setMembers(record.clubmembers.filter((rec: any) => rec.status === "ACCEPTED"));
    //console.log("members", members);
    // const { data: resultPosts } = useGetMany("club/posts/byClubId", { ids: [record.id] });
    // const { data: resultEvents } = useGetMany("event", { ids: [record.id] });
    // const { data: resultGroups } = useGetMany("group", { ids: [record.id] });
    // setPosts(resultPosts);
    // setEvents(resultEvents);
    // setGroups(resultGroups);
    // console.log("posts", posts);
    return null;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const AcceptRequestButton = (props: any) => {
    const { mutate } = useMutation(
      () => dataProvider.acceptRequest(currentRecord.id, props.userId),
      {
        onSuccess: () => {
          const message = `Successfully added to club members`;
          notify(message, { type: "success" });
          refresh();
        },
        onError: (error) => {
          console.log("error", error);
          const { response } = error as any;
          notify(response?.data?.message || error, { type: "error" });
        },
      },
    );
    return (
      <Button
        label="Accept"
        onClick={() => {
          mutate();
        }}
      />
    );
  };

  const handleDeleteMenu = (id: number, type: string) => {
    deleteOne(type, { id });
    handleClose();
  };

  const ClubImage = () => {
    const record = useRecordContext();
    return record.imageUrl.includes("https") ? (
      <ImageField
        source="imageUrl"
        title="clubimage"
        sx={{
          "& .RaImageField-image": {
            width: "94px",
            height: "94px",
            objectFit: "cover",
            borderRadius: "50%",
            margin: "auto",
          },
        }}
      />
    ) : (
      <div
        style={{
          width: "94px",
          height: "94px",
          objectFit: "cover",
          borderRadius: "50%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: record.color,
        }}>
        <div
          style={{
            borderRadius: "50%",
            width: "80px",
            height: "80px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <p
            style={{
              fontSize: "50px",
            }}>
            {JSON.parse(record.imageUrl).emoji}
          </p>
        </div>
      </div>
    );
  };

  const ClubStatus = () => (
    <FunctionField
      label="status"
      render={(record: { isDeleted: boolean }) =>
        record.isDeleted !== null ? (
          <Chip
            label={record.isDeleted === true ? "Deleted" : "Active"}
            color={record.isDeleted === true ? "default" : "success"}
            size="small"
          />
        ) : null
      }
    />
  );

  const MenuItems = ({ id, type }: { id: number; type: string }) => (
    <>
      <MenuItem onClick={() => handleDeleteMenu(id, type)} dense>
        <ListItemIcon>
          <DeleteOutline fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </>
  );

  const BulkMembersButtons = (props: any) => {
    const unselectAll = useUnselectAll(props.resource);
    const { selectedIds } = props;
    const clubId = currentRecord?.id;
    const [deleteMany, { isLoading, error }] = useDeleteMany();

    if (error) {
      const { response } = error as any;
      notify(response?.data?.message || error, { type: "error" });
    }

    const handleClick = () => {
      deleteMany(`clubs/${clubId}/members`, { ids: selectedIds });
      unselectAll();
      refresh();
    };
    return (
      <Fragment>
        <Button
          disabled={isLoading}
          label="DELETE"
          startIcon={<DeleteOutline />}
          className="hover:bg-transparent"
          onClick={handleClick}
          color="error"
        />
      </Fragment>
    );
  };

  const MemberList = () => {
    const record = useRecordContext();
    // const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMoreActions = Boolean(anchorEl);
    const [currentId, setCurrentId] = useState<number>(0);
    // const handleAddMember = () => {
    //   setAddMemberModalOpen(true);
    // };

    // const handleAddMemberClose = () => {
    //   setAddMemberModalOpen(false);
    // };

    const handleClick = (event: MouseEvent<HTMLButtonElement>, userId: number) => {
      setAnchorEl(event.currentTarget);
      setCurrentId(userId);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const ActionButtons = () => {
      const isDeleted = record?.isDeleted;
      const { mutate } = useMutation(
        () => dataProvider.makePresident(currentRecord.id, currentId),
        {
          onSuccess: () => {
            const message = `Successfully updated a club's president`;
            notify(message, { type: "success" });
            refresh();
          },
          onError: (error) => {
            console.log("error", error);
            const { response } = error as any;
            notify(response?.data?.message || error, { type: "error" });
          },
        },
      );
      const handleMakePresident = () => {
        handleClose();
        mutate();
      };

      const handleDelete = () => {
        handleClose();
        deleteOne(`clubs/${currentRecord.id}/deleteMember`, { id: currentId });
        notify("Member deleted", { type: "success" });
      };

      return (
        <>
          {!isDeleted && (
            <>
              <MenuItem onClick={() => handleDelete()} dense>
                <ListItemIcon>
                  <DeleteOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </>
          )}

          <MenuItem onClick={() => handleMakePresident()} dense>
            <ListItemIcon>
              <PersonPin fontSize="small" />
            </ListItemIcon>
            <ListItemText>Make President</ListItemText>
          </MenuItem>
        </>
      );
    };

    const ListActions = () => (
      <TopToolbar>
        {/* <SelectColumnsButton /> */}
        {/* <Button startIcon={<Add />} onClick={() => handleAddMember()} label="Add Member" /> */}
      </TopToolbar>
    );
    return (
      <List
        actions={<ListActions />}
        resource={`clubs/${record.id}/members`}
        storeKey="clubMembers"
        empty={<ClubEmptyFeedCard text="No members founds" />}>
        <DatagridConfigurable bulkActionButtons={<BulkMembersButtons />}>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            label="Name"
            render={(memberRecord: { memberName: string; userId: number }) => {
              return (
                <>
                  {memberRecord.memberName}
                  {memberRecord.userId === record.clubPresident && (
                    <Chip
                      sx={{
                        "&.MuiChip-root": {
                          height: "15px",
                          fontSize: "10px",
                          paddingLeft: 0,
                          paddingRight: 0,
                          alignSelf: "center",
                        },
                        "&.MuiChip-label": {
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        },
                      }}
                      label="PRESIDENT"
                    />
                  )}
                </>
              );
            }}
          />
          <FunctionField
            className="flex justify-end"
            textAlign="right"
            label="Date Joined"
            render={(memberRecord: { createdAt: string; userId: number }) => {
              const dt = DateTime.fromISO(record.createdAt);
              return (
                <div className="flex justify-end items-center">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                  {memberRecord.userId !== record.clubPresident && (
                    <DropdownButtons
                      anchorEl={anchorEl}
                      open={openMoreActions}
                      onClose={handleClose}
                      onClick={(e) => handleClick(e, memberRecord.userId)}>
                      <ActionButtons />
                    </DropdownButtons>
                  )}
                </div>
              );
            }}
          />
        </DatagridConfigurable>
        {/* <AddMemberModal
          open={addMemberModalOpen}
          onClose={handleAddMemberClose}
          // email={handleSubmitEmail}
        >
          <UpdatePresidentButton />
        </AddMemberModal> */}
      </List>
    );
  };

  const ReviewList = () => {
    const record = useRecordContext();
    const ListActions = () => (
      <TopToolbar>
        {/* <SelectColumnsButton /> */}
        {/* <CreateButton /> */}
      </TopToolbar>
    );
    return (
      <List
        actions={<ListActions />}
        empty={<ClubEmptyFeedCard text="No requests founds" />}
        emptyWhileLoading={true}
        exporter={false}
        resource={`clubs/${record.id}/requests`}
        storeKey="clubRequests">
        <Datagrid>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            source="memberName"
            label="Name"
            render={(record: { memberName: string }) => {
              return record.memberName;
            }}
          />
          <TextField
            className="flex justify-center"
            textAlign="center"
            source="status"
            label="Status"
          />
          <FunctionField
            className="flex justify-end"
            textAlign="right"
            label="Date Joined"
            source="createdAt"
            render={(record: { createdAt: string }) => {
              const dt = DateTime.fromISO(record.createdAt);
              return <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>;
            }}
          />
          <FunctionField
            className="flex justify-center"
            textAlign="center"
            render={(record: { status: string; userId: number }) => {
              return record.status === "PENDING" && <AcceptRequestButton userId={record.userId} />;
            }}
          />
        </Datagrid>
      </List>
    );
  };
  return (
    <>
      <ContentHeader />
      <Show
        emptyWhileLoading
        actions={false}
        sx={{
          "& .RaShow-card": {
            border: "none",
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}>
        <GetData />
        <div className="grid md:grid-cols-4">
          <div className="sm:col-span-4 md:col-span-1">
            <ClubProfileCard
              status={<ClubStatus />}
              title={<TextField source="title" sx={{ fontSize: "24px", fontWeight: "bold" }} />}
              image={<ClubImage />}
              description={<TextField source="description" sx={{ fontSize: "20px" }} />}
              school={
                <TextField source="school.name" sx={{ fontSize: "13px", fontStyle: "italic" }} />
              }
            />
          </div>

          <div className="sm:col-span-4 md:col-span-3 lg:col-span-3">
            <SimpleShowLayout>
              <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="fullWidth" />}>
                <Tab label="members">
                  <MemberList />
                </Tab>
                <Tab label="requests">
                  <ReviewList />
                </Tab>
                {/* <Tab label="post">
                  {posts?.length ? (
                    posts.map((p) => (
                      <ClubFeedCard
                        key={p.id}
                        title={p.title}
                        createdAt={p.createdAt}
                        content={p.content}
                        tags={p.tags}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={p.id} type="post" />
                        </DropdownActionButtons>
                      </ClubFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Post found." />
                  )}
                </Tab> */}
                {/*
                <Tab label="events">
                  {events?.length ? (
                    events.map((p) => (
                      <EventFeedCard
                        key={p.id}
                        title={p.title}
                        createdAt={p.createdAt}
                        description={p.description}
                        tags={p.tags}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={p.id} type="events" />
                        </DropdownActionButtons>
                      </EventFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Event found." />
                  )}
                </Tab>
                <Tab label="groups">
                  {groups?.length ? (
                    groups.map((p) => (
                      <ClubFeedCard
                        key={p.id}
                        title={p.title}
                        createdAt={p.createdAt}
                        content={p.content}
                        tags={p.tags}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={p.id} type="groups" />
                        </DropdownActionButtons>
                      </ClubFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Group found." />
                  )}
                </Tab>
                */}
              </TabbedShowLayout>
            </SimpleShowLayout>
          </div>
        </div>
      </Show>
    </>
  );
};
