import { defaultTheme } from "react-admin";

type ShadowType = [
  "none",
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

const shadows = new Array(25).join(".").split(".") as ShadowType;
shadows[0] = "none";

export const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "rgb(160, 39, 39)",
    },
    // success: {
    //   main: "#E3EBFF",
    //   contrastText: "#3B72FF",
    // },
  },
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(
      ",",
    ),
  },
  shadows,
  components: {
    ...defaultTheme.components,
    RaLogin: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8F8F8",
          backgroundImage: "none",
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
        headerTitile: {
          color: "",
        },
      },
    },
    // RaMenuItemLink: {
    //   styleOverrides: {
    //     root: {
    //       borderLeft: "3px solid #FFF",
    //       "&.RaMenuItemLink-active": {
    //         color: "rgba(0, 0, 0, 0.6)",
    //       },
    //     },
    //   },
    // },
    RaAppBar: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    // RaLoadingIndicator: {
    //   styleOverrides: {
    //     root: {
    //       display: "none",
    //     },
    //   },
    // },
    // RaUserMenu: {
    //   styleOverrides: {
    //     root: {
    //       display: "none",
    //     },
    //   },
    // },
    // RaSidebarToggleButton: {
    //   styleOverrides: {
    //     root: {
    //       display: "none",
    //     },
    //   },
    // },
    // RaListContent: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: "none",
    //     },
    //   },
    // },
  },
};

export const themeBondo = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#409de2",
    },
    // success: {
    //   main: "#E3EBFF",
    //   contrastText: "#3B72FF",
    // },
  },
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(
      ",",
    ),
  },
  shadows,
  components: {
    ...defaultTheme.components,
    RaLogin: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8F8F8",
          backgroundImage: "none",
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
    },
    // RaMenuItemLink: {
    //   styleOverrides: {
    //     root: {
    //       borderLeft: "3px solid #FFF",
    //       "&.RaMenuItemLink-active": {
    //         color: "rgba(0, 0, 0, 0.6)",
    //       },
    //     },
    //   },
    // },
    RaAppBar: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    // RaLoadingIndicator: {
    //   styleOverrides: {
    //     root: {
    //       display: "none",
    //     },
    //   },
    // },
    // RaUserMenu: {
    //   styleOverrides: {
    //     root: {
    //       display: "none",
    //     },
    //   },
    // },
    // RaSidebarToggleButton: {
    //   styleOverrides: {
    //     root: {
    //       display: "none",
    //     },
    //   },
    // },
    // RaListContent: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: "none",
    //     },
    //   },
    // },
  },
};
