import {
  AutocompleteInput,
  Datagrid,
  FilterButton,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  ReferenceInput,
  RowClickFunction,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Block, DeleteOutline } from "@mui/icons-material";
import { BulkActionButtons, DropdownButtons } from "../../component/button";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";

import Chip from "@mui/material/Chip";
import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { UserFilterFormModal } from "../../component/modal";

const CustomEmpty = () => <div>No Users found</div>;

const filters = [
  <TextInput source="q" label="Search" alwaysOn variant="outlined" size="small" resettable />,
  <ReferenceInput source="name" label="Student Name" reference="users">
    <AutocompleteInput
      optionText="name"
      label="Student"
      variant="outlined"
      size="small"
      optionValue="title"
    />
  </ReferenceInput>,
  <ReferenceInput source="school.shortName" label="School" reference="users">
    <AutocompleteInput
      optionText="school"
      label="School"
      variant="outlined"
      size="small"
      optionValue="school.shortName"
    />
  </ReferenceInput>,
];

type Record = {
  id: number;
  name: string;
  isDeleted: boolean;
  isActive: boolean;
  isBlockDeleted: boolean;
  isUnBanned: boolean;
  school: any;
};

export const UserList = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentResource, setCurrentResource] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<RaRecord>();
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("currentId", currentId);

  console.log("currentRecord", currentRecord);

  console.log("currentResource", currentResource);

  const renderLabel = (record: Record) => {
    if (record.isUnBanned === false && record.isActive === true) return "Banned";
    if (record.isDeleted === true) return "Deleted";
    if (record.isBlockDeleted === false) return "Blocked";
    if (record.isActive === true) return "Active";
    if (record.isActive === false) return "Inactive";
  };

  const renderStatusColor = (record: Record) => {
    if (record.isUnBanned === false && record.isActive === true) return "error";
    if (record.isDeleted === true) return "warning";
    if (record.isBlockDeleted === false) return "warning";
    if (record.isActive === true) return "success";
    if (record.isActive === false) return "default";
  };

  const handleDeleteMenu = () => {
    dataProvider.delete(currentResource, { id: currentId });
    handleClose();
    refresh();
  };

  const handleBlockUser = async () => {
    handleClose();
    const { success, message } = await dataProvider.blockUser({
      blockedUserId: currentId,
      blockedType: "blocked",
    });
    if (success) {
      notify(message, { type: "success" });
      refresh();
    }
  };

  const handleBanUser = async () => {
    handleClose();
    const { success, message } = await dataProvider.banUser({
      email: currentRecord?.email,
      description: "Banned",
    });
    if (success) {
      notify(message, { type: "success" });
      refresh();
    }
  };

  const handleUnblockUser = async () => {
    handleClose();
    const { success, message } = await dataProvider.unblockUser({
      blockedUserId: currentId,
      blockedType: "blocked",
    });
    if (success) {
      notify(message, { type: "success" });
      refresh();
    }
  };

  // const hanldeEditMenu = () => {
  //   redirect(currentId.toString());
  //   handleClose();
  //   refresh();
  // };

  const handleSubmitFilter = (values: any) => {
    setFilterModalOpen(false);
  };

  const ActionButtons = () => {
    const isBlockDeleted = currentRecord?.isBlockDeleted || true;
    const isDeleted = currentRecord?.isDeleted;
    const isUnBanned = currentRecord?.isUnBanned;
    console.log("currentRecord?.isUnBanned", currentRecord?.isUnBanned);
    console.log("isUnBanned", isUnBanned);
    const status =
      isUnBanned === false
        ? "Banned"
        : isDeleted
        ? "Deleted"
        : isBlockDeleted
        ? "Blocked"
        : "Active";

    console.log("status", status);

    return (
      <>
        {status === "Banned" && (
          <MenuItem onClick={() => console.log("Do Nothing")} dense>
            <ListItemIcon>
              <Block fontSize="small" />
            </ListItemIcon>
            <ListItemText>No Actions Available</ListItemText>
          </MenuItem>
        )}
        {status === "Active" && (
          <>
            <MenuItem onClick={handleBlockUser} dense>
              <ListItemIcon>
                <Block fontSize="small" />
              </ListItemIcon>
              <ListItemText>Block</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleBanUser} dense>
              <ListItemIcon>
                <Block fontSize="small" />
              </ListItemIcon>
              <ListItemText>Ban</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDeleteMenu} dense>
              <ListItemIcon>
                <DeleteOutline fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </>
        )}
        {status === "Blocked" && (
          <>
            <MenuItem onClick={handleUnblockUser} dense>
              <ListItemIcon>
                <Block fontSize="small" color="disabled" />
              </ListItemIcon>
              <ListItemText>Unblock</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleBanUser} dense>
              <ListItemIcon>
                <Block fontSize="small" />
              </ListItemIcon>
              <ListItemText>Ban</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDeleteMenu} dense>
              <ListItemIcon>
                <DeleteOutline fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </>
        )}
      </>
    );
  };

  const renderUserName = (record: Record) => {
    return record.isDeleted ? (
      <TextField source="name" sx={{ padding: "6px 12px" }} />
    ) : (
      <ShowButton label={record.name} icon={<></>} sx={{ textTransform: "none" }} />
    );
  };

  const renderSchoolName = (record: Record) => {
    return record.school.shortName;
    // return <ShowButton label={record.school.shortName} icon={<></>} />;
  };

  const setCurrentRow: RowClickFunction = (
    id: Identifier,
    resource: string,
    record: RaRecord,
  ): string => {
    setCurrentId(id);
    setCurrentResource(resource);
    setCurrentRecord(record);
    return "";
  };

  return (
    <>
      <ContentHeader />
      <List
        sort={{ field: "createdAt", order: "DESC" }}
        // filters={filters}
        exporter={false}
        hasCreate={false}>
        <Datagrid
          bulkActionButtons={
            localStorage.getItem("userRole") === "superAdmin" && <BulkActionButtons />
          }
          empty={<CustomEmpty />}
          sx={{
            "& .column-title": { width: "200px" },
            "& .column-createdAt": { width: "100px", textAlign: "right" },
          }}
          rowClick={setCurrentRow}>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            source="name"
            label="Student Name"
            render={(record: Record) => renderUserName(record)}
          />
          <FunctionField
            className="justify-center"
            source="status"
            label="Status"
            render={(record: Record) => (
              <Chip label={renderLabel(record)} color={renderStatusColor(record)} size="small" />
            )}
          />
          <FunctionField
            label="Email"
            source="email"
            render={(record: { email: string }) => record.email}
          />
          {localStorage.getItem("slug") === "bondo" && (
            <FunctionField
              label="School"
              source="school"
              render={(record: Record) => renderSchoolName(record)}
            />
          )}
          <FunctionField
            label="Date Joined"
            source="createdAt"
            render={(record: {
              createdAt: string;
              isBlockDeleted: boolean;
              isUnBanned: boolean;
            }) => {
              const dt = DateTime.fromISO(record.createdAt);
              return (
                <div className="flex justify-end items-center">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                  <DropdownButtons
                    anchorEl={anchorEl}
                    open={openMoreActions}
                    onClose={handleClose}
                    onClick={handleClick}>
                    <ActionButtons />
                  </DropdownButtons>
                </div>
              );
            }}
          />
        </Datagrid>
      </List>
      <UserFilterFormModal
        open={filterModalOpen}
        onClose={undefined}
        onSubmit={handleSubmitFilter}
        options={{ displayedFilters, filterValues, setFilters, hideFilter }}
      />
    </>
  );
};
