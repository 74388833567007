import { ClubsInterface, ClubsRequestInterface } from "../type/clubs";

import { instance } from "../helper/api";

export const ClubsRequest: ClubsRequestInterface = {
  createMany: async (params: ClubsInterface) => {
    console.log("createMany", params);
    const r = await instance.post(`/club/bulk/`, { data: params });
    return await Promise.resolve(r.data);
  },
  acceptRequest: async (clubId: number, userId: number) => {
    const r = await instance.post(`/clubs/accept`, { clubId, userId });
    return await Promise.resolve(r.data);
  },
  makePresident: async (clubId: number, userId: number) => {
    const r = await instance.post(`/clubs/makePresident`, { clubId, userId });
    return await Promise.resolve(r.data);
  },
};
