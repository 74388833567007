import { Button, Chip } from "@mui/material";
import { Cell, Sheet } from "../../component/grid";
import { SetStateAction, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";

import CSVReader from "react-csv-reader";
import { GridElement } from "../../type/grid";
import { HelpOutline } from "@mui/icons-material";
import { LightTooltip } from "../../component/tooltip";
import ReactDataSheet from "react-datasheet";
import { findIndex } from "lodash";
import { useMutation } from "react-query";
import { validateEmail } from "../../helper/string";

class ClubDataSheet extends ReactDataSheet<GridElement, string> {}

const initialRows: GridElement[][] = [
  [
    { value: "", name: "title" },
    { value: "", name: "email" },
    { value: "", name: "type" },
  ],
  [
    { value: "", name: "title" },
    { value: "", name: "email" },
    { value: "", name: "type" },
  ],
  [
    { value: "", name: "title" },
    { value: "", name: "email" },
    { value: "", name: "type" },
  ],
];

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
};

export const GroupCreate = () => {
  const [rows, setRows] = useState(initialRows);
  const [csv, setCsv] = useState([]);
  const [fileInfo, setFileInfo] = useState({ name: "" });
  const notify = useNotify();

  const createAnother = () => {
    setRows(
      rows.concat([
        [
          { value: "", name: "title" },
          { value: "", name: "email" },
          { value: "", name: "type" },
        ],
      ]),
    );
  };

  const handleFileDelete = () => {
    setFileInfo({ name: "" });
    setCsv([]);
  };

  const UploadCsvButton = () => {
    const dataProvider = useDataProvider();
    const { mutate, isLoading } = useMutation(["uploadManyClubs", csv], () => {
      return dataProvider.createMany(csv);
    });
    return (
      <div className="flex items-end gap-4 mb-4">
        {fileInfo.name ? (
          <Chip label={fileInfo.name} onDelete={handleFileDelete} size="medium" />
        ) : null}
        <Button variant="contained" type="button" onClick={() => mutate()} disabled={isLoading}>
          Click to upload
        </Button>
      </div>
    );
  };

  const onUploaded = (data: SetStateAction<any[]>, fileInfo: any) => {
    setCsv(data as SetStateAction<never[]>);
    setFileInfo(fileInfo);
  };

  const filterOutEmptyRow = (u: GridElement[]) => {
    return (
      u[findIndex(u, (o) => o.name === "title")].value ||
      u[findIndex(u, (o) => o.name === "email")].value ||
      u[findIndex(u, (o) => o.name === "type")].value
    );
  };

  const validateInput = (data: GridElement[][]) => {
    return data.filter(filterOutEmptyRow).map((r) => {
      const title = r[findIndex(r, (o) => o.name === "title")].value;
      const email = r[findIndex(r, (o) => o.name === "email")].value;
      const type = r[findIndex(r, (o) => o.name === "type")].value;

      if (!title || !email || !type) {
        const message = `All fields are required`;
        notify(message, { type: "error" });
        throw new Error(message);
      }

      const isValidEmail = validateEmail(email);
      if (!isValidEmail) {
        const message = `${email} is not a valid email`;
        notify(message, { type: "error" });
        throw new Error(message);
      }

      return {
        title,
        email,
        type,
        bio: "",
        imageUrl: "",
        insta: "",
        linkedin: "",
        members: [],
        tags: [],
        tiktok: "",
      };
    });
  };

  const PublishAllButton = () => {
    const dataProvider = useDataProvider();
    const isEmpty = rows.filter(filterOutEmptyRow).length === 0;
    const { mutate, isLoading, isSuccess } = useMutation(["createManyClubs", rows], () => {
      const validatedRows = validateInput(rows);
      return dataProvider.createMany(validatedRows);
    });

    return (
      <Button
        type="button"
        onClick={() => mutate()}
        disabled={isEmpty || isLoading}
        variant="contained"
      >
        Publish all
      </Button>
    );
  };

  type FiledType = { cell: GridElement | null; row: number; col: number; value: string | null };

  const onFieldChange = (
    changes: ReactDataSheet.CellsChangedArgs<GridElement, string> | undefined,
  ) => {
    const grid = rows.map((row) => [...row]);
    changes?.forEach(({ row, col, value }: FiledType) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    setRows(grid);
  };

  return (
    <>
      <div className="grid justify-items-left m-4">
        <span className="text-2xl">Create Clubs</span>
      </div>
      <div className="flex justify-end w-full mb-4">
        {csv.length ? (
          <UploadCsvButton />
        ) : (
          <div className="mb-4">
            <CSVReader
              cssClass="react-csv-input"
              cssInputClass="csv-input"
              cssLabelClass="csv-label"
              label="Upload CSV"
              onFileLoaded={onUploaded}
              parserOptions={parseOptions}
            />
          </div>
        )}
        <LightTooltip title="Required on header are title, email and type.">
          <HelpOutline color="disabled" fontSize="medium" />
        </LightTooltip>
      </div>
      <div className="w-full">
        <ClubDataSheet
          data={rows}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={onFieldChange}
          cellRenderer={Cell}
          sheetRenderer={Sheet}
        />
        <div className="flex w-full justify-end">
          <div className="mr-2">
            <Button type="button" onClick={createAnother} variant="contained">
              Create another
            </Button>
          </div>
          <div>
            <PublishAllButton />
          </div>
        </div>
      </div>
    </>
  );
};
