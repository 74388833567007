import { Button, Chip, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ClubEmptyFeedCard, PostFeedCard, UserProfileCard } from "../../component/card";
import { DeleteOutline, DoNotDisturbAlt } from "@mui/icons-material";
import {
  FunctionField,
  Identifier,
  ImageField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  useDataProvider,
  useDelete,
  useGetList,
  useNotify,
  useRecordContext,
} from "react-admin";
import { MouseEvent, useState } from "react";

import { ContentHeader } from "../../component/header/content";
import { DropdownActionButtons } from "../../component/button";
import { PauseModal } from "../../component/modal/pause";
import { setgroups } from "process";
import { useMutation } from "react-query";

export const UserShow = ({ userId }: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [pauseModalOpen, setPauseModalOpen] = useState<boolean>(false);
  const [posts, setPosts] = useState<any[] | undefined>([]);
  const [events, setEvents] = useState<any[] | undefined>([]);
  const [groups, setGroups] = useState<any[] | undefined>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [pauseComment, setPauseComment] = useState<string>("");
  const openMoreActions = Boolean(anchorEl);

  const setQuery = () => {
    const record = useRecordContext();
    return {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "createdAt", order: "DESC" as const },
      filter: { createdBy: record.id },
    };
  };

  const GetData = () => {
    const { data: resultPosts } = useGetList("post", setQuery());
    console.log("resultPosts", resultPosts);
    // const { data: resultEvents } = useGetList("event", setQeury());
    const { data: resultGroups } = useGetList("groups", setQuery());
    console.log("resultGroups", resultGroups);
    setPosts(resultPosts);
    setGroups(resultGroups);
    // setEvents(resultEvents);

    return null;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = (id: number, type: string) => {
    switch (type) {
      case "post":
        dataProvider.deletePost({ id });
        break;
      case "events":
      case "groups":
    }
    handleClose();
  };

  const handlePauseMenu = (id: number) => {
    setCurrentAction("Pause");
    setCurrentId(id);
    handleClose();
    setPauseModalOpen(true);
    return;
  };

  const handleSubmitPause = (comment: { textReason: string }) => {
    console.log("textReason", comment);
    setPauseComment(comment.textReason);
  };

  const SubmitPauseButton = () => {
    const payload = {
      type: "clubs",
      reportedId: currentId,
      comment: pauseComment,
    };
    const { mutate, isSuccess } = useMutation(() => dataProvider.report(payload));

    if (isSuccess) {
      const message = `Successfully pause a cLub.`;
      notify(message, { type: "success" });
    }

    return (
      <Button variant="contained" onClick={() => mutate()}>
        Pause
      </Button>
    );
  };

  const handlePauseModalClose = () => {
    setPauseModalOpen(false);
  };

  const MenuItems = ({ id, type }: { id: number; type: string }) => (
    <>
      <MenuItem onClick={() => handleDeleteMenu(id, type)} dense>
        <ListItemIcon>
          <DeleteOutline fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handlePauseMenu(id)} dense>
        <ListItemIcon>
          <DoNotDisturbAlt fontSize="small" color="disabled" />
        </ListItemIcon>
        <ListItemText>Pause</ListItemText>
      </MenuItem>
    </>
  );

  const UserImage = () => (
    <ImageField
      source="imageUrl"
      title="profile"
      sx={{
        "& .RaImageField-image": {
          width: "94px",
          height: "94px",
          objectFit: "cover",
          borderRadius: "50%",
          margin: "auto",
          background: "#A02727",
        },
      }}
    />
  );

  const UserStatus = () => (
    <FunctionField
      label="status"
      render={(record: { isActive: boolean }) =>
        record.isActive !== null ? (
          <Chip
            label={record.isActive === true ? "Active" : "Deleted"}
            color={record.isActive === true ? "success" : "default"}
            size="small"
          />
        ) : null
      }
    />
  );

  return (
    <>
      <ContentHeader />
      <Show
        emptyWhileLoading
        actions={false}
        sx={{
          "& .RaShow-card": {
            border: "none",
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}>
        <GetData />
        <div className="grid md:grid-cols-4">
          <div className="sm:col-span-4 md:col-span-1">
            <UserProfileCard
              name={<TextField source="name" sx={{ fontSize: "24px" }} />}
              image={<UserImage />}
              status={<UserStatus />}
            />
          </div>
          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2">
            <SimpleShowLayout>
              <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="fullWidth" className="w-[600px]" />}>
                <Tab label="post">
                  {posts?.length ? (
                    posts.map((post: any) => (
                      <PostFeedCard
                        key={post.id}
                        id={post.id}
                        userInfo={post.userInfo}
                        createdAt={post.createdAt}
                        content={post.content}
                        reasons={post.resons}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={post.id} type="post" />
                        </DropdownActionButtons>
                      </PostFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Post found." />
                  )}
                </Tab>
                {/* <Tab label="events">
                  {events?.length ? (
                    events.map((post) => (
                      <PostFeedCard
                        key={post.id}
                        id={post.id}
                        userInfo={post.userInfo}
                        createdAt={post.createdAt}
                        content={post.content}
                        reasons={post.resons}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={post.id} type="events" />
                        </DropdownActionButtons>
                      </PostFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Event found." />
                  )}
                </Tab> */}
                <Tab label="groups">
                  {groups?.length ? (
                    groups.map((post) => (
                      <PostFeedCard
                        key={post.id}
                        id={post.id}
                        userInfo={post.userInfo}
                        createdAt={post.createdAt}
                        content={post.content}
                        reasons={post.resons}>
                        <DropdownActionButtons
                          anchorEl={anchorEl}
                          open={openMoreActions}
                          onClose={handleClose}
                          onClick={handleClick}>
                          <MenuItems id={post.id} type="groups" />
                        </DropdownActionButtons>
                      </PostFeedCard>
                    ))
                  ) : (
                    <ClubEmptyFeedCard text="No Group found." />
                  )}
                </Tab>
              </TabbedShowLayout>
            </SimpleShowLayout>
          </div>
        </div>
      </Show>
      <PauseModal
        open={pauseModalOpen}
        onClose={handlePauseModalClose}
        pause={handleSubmitPause}
        title={currentAction}>
        <SubmitPauseButton />
      </PauseModal>
    </>
  );
};
