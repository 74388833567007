import { useGetResourceLabel, useResourceContext } from "react-admin";

export const ResourceLabel = (props: any) => {
  const { subTitle } = props;
  const name = useResourceContext();
  const resource = useGetResourceLabel();

  return (
    <>
      <span className="text-2xl">
        {name.includes("report") ? "Manage Content - " : ""}
        {resource(name, 2)}
        {subTitle ? ` - ${subTitle}` : ""}
      </span>
    </>
  );
};
