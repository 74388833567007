import { instance } from "../helper/api";
import { ReportInterface, ReportRequestInterface } from "../type/report";

export const ReportRequest: ReportRequestInterface = {
  report: (params: ReportInterface) => instance.post(`/report/admin/action`, params),
  reportMany: async (params: ReportInterface) => {
    const r = await instance.post(`/report/admin/bulkReport`, params);
    return await Promise.resolve(r.data);
  },
};
