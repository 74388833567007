import { Chip, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ClubEmptyFeedCard, ProfileFeedCard } from "../../component/card/feed";
import { DoNotDisturbAlt, FlagOutlined } from "@mui/icons-material";
import { List, useDataProvider, useDelete, useGetList, useNotify, useRefresh } from "react-admin";
import { MouseEvent, useState } from "react";

import { ContentHeader } from "../../component/header/content";
import { DropdownActionButtons } from "../../component/button";

export const ReportedProfileList = () => {
  const [deleteOne] = useDelete();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [currentId, setCurrentId] = useState<number>(0);
  const [reportUserId, setReportUserId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(anchorEl);

  const setQuery = () => {
    return {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "createdAt", order: "DESC" as const },
      filter: {},
    };
  };

  const { data: reports } = useGetList("report/admin/profiles", setQuery());

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: number, reportUserId: number) => {
    setReportUserId(reportUserId);
    setCurrentId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeepUser = () => {
    handleClose();
    deleteOne("report/admin/delete", { id: currentId });
    notify("Report deleted", { type: "success" });
    refresh();
  };

  const handleBlockUser = () => {
    handleClose();
    deleteOne("report/admin/delete", { id: currentId });
    const { success, message } = dataProvider.blockUser({
      blockedUserId: reportUserId,
      blockedType: "blocked",
      reason: "Admin blocked",
    });
    if (success) {
      notify(message, { type: "success" });
      refresh();
    }
  };

  return (
    <>
      <ContentHeader />
      <List
        emptyWhileLoading
        pagination={false}
        sx={{
          backgroundColor: "transparent",
          "& .RaList-content": {
            backgroundColor: "transparent",
          },
        }}>
        <div className="flex flex-col items-center">
          <div className="w-[600px] my-6">
            <span className="font-semibold">Action Required:</span>{" "}
            <Chip label={reports?.length} variant="filled" color="primary" size="small" />
          </div>
          {reports?.length === 0 ? <ClubEmptyFeedCard text="No Reported Profile" /> : null}
          {reports?.map((profile: any) => (
            <ProfileFeedCard
              key={profile.id}
              id={profile.id}
              reportedUser={profile.reportedUser}
              reporter={profile.reporter}
              createdAt={profile.createdAt}
              comment={profile.comment}>
              <DropdownActionButtons
                anchorEl={anchorEl}
                open={openMoreActions}
                onClose={handleClose}
                onClick={(e) => handleClick(e, profile.id, profile.reportedId)}>
                <MenuItem onClick={() => handleKeepUser()} dense>
                  <ListItemIcon>
                    <FlagOutlined fontSize="small" color="disabled" />
                  </ListItemIcon>
                  <ListItemText>Keep</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleBlockUser()} dense>
                  <ListItemIcon>
                    <DoNotDisturbAlt fontSize="small" color="disabled" />
                  </ListItemIcon>
                  <ListItemText>Ban</ListItemText>
                </MenuItem>
              </DropdownActionButtons>
            </ProfileFeedCard>
          ))}
        </div>
      </List>
    </>
  );
};
