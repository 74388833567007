import { Button } from "@mui/material";
import ContentFilter from "@mui/icons-material/FilterList";

type Props = {
  onClick: () => void;
};

export const FilterButton = (props: Props) => {
  const { onClick } = props;
  return (
    <Button
      size="medium"
      color="primary"
      variant="contained"
      onClick={onClick}
      startIcon={<ContentFilter />}
    >
      Filters
    </Button>
  );
};
