import * as React from "react";
import { forwardRef } from "react";
import { useLogout } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import { ExitToApp } from "@mui/icons-material";

export const LogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => logout();
  return (
    <MenuItem onClick={handleClick}>
      <ExitToApp className="mr-3" /> Logout
    </MenuItem>
  );
});
