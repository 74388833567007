import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider, useRedirect } from "react-admin";

import { Link } from "react-router-dom";
import { UserRequest } from "../../api/user";
import { useState } from "react";
import { validateEmail } from "../../helper/string";

export const Register = (props: any) => {
  const redirect = useRedirect();
  const [email, setEmail] = useState("");
  // const [university, setUniversity] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [slug, setSlug] = useState("bondo");

  const isFormNotFilled = () =>
    !email || !validateEmail(email) || !password || !firstname || !lastname ? true : false;

  const SubmitButton = () => {
    const payload = {
      email,
      name: `${firstname} ${lastname}`,
      password,
      slug,
    };

    return (
      <Button
        fullWidth
        variant="contained"
        size="medium"
        sx={{ mt: 3, mb: 2 }}
        onClick={() =>
          UserRequest.registerUser(payload).then((r) => {
            // add notification
          })
        }
        disabled={isFormNotFilled()}>
        Register now
      </Button>
    );
  };

  return (
    <ThemeProvider theme={props.theme}>
      <Container component="main" maxWidth="xs" className="h-screen bg-transparent">
        <CssBaseline />
        <div className="h-full flex items-center">
          <div>
            <div className="flex justify-center mb-10">
              <Typography component="h1" variant="h3" fontWeight="bold" color="primary">
                OFFLINE Admin
              </Typography>
            </div>
            <div className="mb-40">
              <Card
                className="w-[425px] rounded-lg bg-white"
                sx={{
                  borderRadius: "33px",
                  padding: "14px",
                }}>
                <CardContent>
                  <Typography component="h2" variant="h5">
                    Welcome!
                  </Typography>
                  <Typography component="p" variant="body1" color="gray">
                    Your univeristy admin will approve this request to register a new account.
                  </Typography>
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    {/*
                      <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  */}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      variant="outlined"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="firstname"
                      label="Firstname"
                      name="firstname"
                      variant="outlined"
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="lastname"
                      label="Lastname"
                      name="lastname"
                      variant="outlined"
                      onChange={(e) => setLastname(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <SubmitButton />
                  </Box>
                </CardContent>
              </Card>
              {/* <Grid container justifyContent="center">
                <Grid item>
                  <Link to="/login">{"Already have an account? Log in"}</Link>
                </Grid>
              </Grid> */}
            </div>
          </div>
        </div>
      </Container>
    </ThemeProvider>
  );
};
