import { BulkActionButtons, DropdownButtons } from "../../component/button";
import { Chip, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  RowClickFunction,
  ShowButton,
  TopToolbar,
  useDataProvider,
} from "react-admin";
import { MouseEvent, useState } from "react";

import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { DeleteOutline } from "@mui/icons-material";

const CustomEmpty = () => <div>No Groups found</div>;

type Record = {
  id: number;
  title: string;
  description: string;
  isDeleted: boolean;
  isActive: boolean;
  isHidden: boolean;
  createdAt: any;
  date: any;
  location: string;
  school: any;
};

export const GroupList = () => {
  const dataProvider = useDataProvider();
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentResource, setCurrentResource] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<RaRecord>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    if (currentResource === "groups") {
      dataProvider.delete("group", { id: currentId });
    } else {
      dataProvider.delete(currentResource, { id: currentId });
    }
    handleClose();
  };

  const renderGroupName = (record: Record) => {
    return (
      <ShowButton
        label={record.title}
        icon={<></>}
        sx={{ textAlign: "left", textTransform: "none" }}
      />
    );
  };

  const setCurrentRow: RowClickFunction = (
    id: Identifier,
    resource: string,
    record: RaRecord,
  ): string => {
    setCurrentId(id);
    setCurrentResource(resource);
    setCurrentRecord(record);
    return "";
  };

  const ListActions = () => (
    <TopToolbar>{/* <CreateButton variant="contained" size="medium" /> */}</TopToolbar>
  );

  const renderLabel = (record: Record) => {
    if (record.isDeleted === true) return "Deleted";
    if (record.isDeleted === false) return "Active";
    if (record.isHidden === true) return "Inactive";
  };

  const renderStatusColor = (record: Record) => {
    if (record.isDeleted === true) return "error";
    if (record.isDeleted === false) return "success";
    if (record.isHidden === true) return "warning";
  };

  return (
    <>
      <ContentHeader />
      <List sort={{ field: "createdAt", order: "DESC" }} actions={<ListActions />}>
        <Datagrid
          bulkActionButtons={<BulkActionButtons />}
          empty={<CustomEmpty />}
          sx={{
            "& .column-title": { width: "200px" },
            "& .column-createdAt": { width: "150px", textAlign: "right" },
          }}
          rowClick={setCurrentRow}>
          <FunctionField
            className="flex justify-start"
            textAlign="left"
            source="title"
            label="Group Name"
            render={(record: Record) => renderGroupName(record)}
          />
          <FunctionField
            className="justify-center"
            textAlign="center"
            source="status"
            label="Status"
            render={(record: Record) => (
              <Chip label={renderLabel(record)} color={renderStatusColor(record)} size="small" />
            )}
          />
          <FunctionField
            label="Description"
            source="description"
            render={(record: { description: string }) => record.description}
          />
          <FunctionField
            label="Date Created"
            source="createdAt"
            render={(record: { createdAt: string; isActive: boolean }) => {
              const dt = DateTime.fromISO(record.createdAt);
              return (
                <div className="flex justify-end items-center">
                  <div>{dt.toLocaleString(DateTime.DATE_SHORT)}</div>
                  <DropdownButtons
                    anchorEl={anchorEl}
                    open={openMoreActions}
                    onClose={handleClose}
                    onClick={handleClick}>
                    <MenuItem onClick={handleDeleteMenu} dense>
                      <ListItemIcon>
                        <DeleteOutline fontSize="small" color="error" />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </DropdownButtons>
                </div>
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
