import {
  Button,
  Datagrid,
  FunctionField,
  Identifier,
  ImageField,
  ListContextProvider,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TextInput,
  useDataProvider,
  useDelete,
  useDeleteMany,
  useNotify,
  useRecordContext,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import {
  CalendarMonthOutlined,
  DeleteOutline,
  EmojiPeopleOutlined,
  HourglassTopOutlined,
  InfoOutlined,
  LocationOnOutlined,
  OpenInBrowserOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import { Chip, Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ClubEmptyFeedCard, EventProfileCard } from "../../component/card";
import { MouseEvent, useState } from "react";

import { ContentHeader } from "../../component/header/content";
import { DateTime } from "luxon";
import { DropdownActionButtons } from "../../component/button";
import { DropdownButtons } from "../../component/button";
import { Fragment } from "react";

export const EventShow = () => {
  const [deleteOne] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();

  const [posts, setPosts] = useState<any[] | undefined>([]);
  const [events, setEvents] = useState<any[] | undefined>([]);
  const [groups, setGroups] = useState<any[] | undefined>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState<Identifier>(0);
  const [currentRecord, setCurrentRecord] = useState<any | undefined>(null);
  const openMoreActions = Boolean(anchorEl);

  const GetData = () => {
    const record = useRecordContext();
    setCurrentRecord(record);
    //setMembers(record.clubmembers.filter((rec: any) => rec.status === "ACCEPTED"));
    //console.log("members", members);
    // const { data: resultPosts } = useGetMany("club/posts/byClubId", { ids: [record.id] });
    // const { data: resultEvents } = useGetMany("event", { ids: [record.id] });
    // const { data: resultGroups } = useGetMany("group", { ids: [record.id] });
    // setPosts(resultPosts);
    // setEvents(resultEvents);
    // setGroups(resultGroups);
    // console.log("posts", posts);
    return null;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = (id: number, type: string) => {
    deleteOne(type, { id });
    handleClose();
  };

  const EventImage = () => {
    const record = useRecordContext();
    const imageUrl = record.imageUrl[0].trim();
    return imageUrl.includes("https") ? (
      <img
        src={imageUrl}
        title="Event Image"
        style={{
          width: "248px",
          height: "248px",
        }}
      />
    ) : (
      <div
        style={{
          width: "94px",
          height: "94px",
          objectFit: "cover",
          borderRadius: "50%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: record.color,
        }}>
        <div
          style={{
            borderRadius: "50%",
            width: "80px",
            height: "80px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <p
            style={{
              fontSize: "50px",
            }}>
            {JSON.parse(record.imageUrl[0]).emoji}
          </p>
        </div>
      </div>
    );
  };

  const EventStatus = () => (
    <FunctionField
      label="status"
      render={(record: { isDeleted: boolean; date: any }) => {
        const dt = DateTime.fromISO(record.date);
        return record.isDeleted !== null ? (
          <Chip
            label={
              record.isDeleted === true ? "Deleted" : dt < DateTime.now() ? "Elapsed" : "Active"
            }
            color={
              record.isDeleted === true ? "error" : dt < DateTime.now() ? "default" : "success"
            }
            size="small"
          />
        ) : null;
      }}
    />
  );

  const AttendeeList = () => {
    const record = useRecordContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMoreActions = Boolean(anchorEl);
    const [currentId, setCurrentId] = useState<number>(0);

    const handleClick = (event: MouseEvent<HTMLButtonElement>, userId: number) => {
      setAnchorEl(event.currentTarget);
      setCurrentId(userId);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const ActionButtons = () => {
      const isDeleted = record?.isDeleted;

      const handleDelete = () => {
        handleClose();
        deleteOne(`clubs/${currentRecord.id}/deleteMember`, { id: currentId });
        notify("Member deleted", { type: "success" });
      };

      return (
        <>
          {!isDeleted && (
            <>
              <MenuItem onClick={() => handleDelete()} dense>
                <ListItemIcon>
                  <DeleteOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </>
          )}
        </>
      );
    };
    return record.membersinfo?.length ? (
      record.membersinfo.map((userRecord: any) => (
        <div className="min-w-[182px] mt-[8px] mb-[8px] bg-white">
          <div className="flex justify-start align-content-center">
            <img
              src={userRecord.imageUrl}
              title="User Image"
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <span
              style={{
                fontSize: "20px",
                fontWeight: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "20px",
              }}>
              {userRecord.name}
            </span>
          </div>
        </div>
      ))
    ) : (
      <ClubEmptyFeedCard text="No Attendees found." />
    );
  };
  return (
    <>
      <ContentHeader />
      <Show
        emptyWhileLoading
        actions={false}
        sx={{
          "& .RaShow-card": {
            border: "none",
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}>
        <GetData />
        <div className="grid md:grid-cols-4">
          <div className="sm:col-span-4 md:col-span-1">
            <EventProfileCard
              status={<EventStatus />}
              title={<TextField source="title" sx={{ fontSize: "24px", fontWeight: "bold" }} />}
              image={<EventImage />}
              description={
                <FunctionField
                  className="flex justify-start"
                  textAlign="left"
                  render={(record: { description: string }) => {
                    return (
                      <div>
                        <InfoOutlined /> {record.description}
                      </div>
                    );
                  }}
                />
              }
              date={
                <FunctionField
                  className="flex justify-start"
                  textAlign="left"
                  render={(record: { date: any }) => {
                    const dt = DateTime.fromISO(record.date);
                    return (
                      <div>
                        <CalendarMonthOutlined />{" "}
                        {dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                      </div>
                    );
                  }}
                />
              }
              location={
                <FunctionField
                  className="flex justify-start"
                  textAlign="left"
                  render={(record: { location: string }) => {
                    return (
                      <div>
                        <LocationOnOutlined /> {record.location}
                      </div>
                    );
                  }}
                />
              }
              time={
                <FunctionField
                  className="flex justify-start"
                  textAlign="left"
                  render={(record: { startTime: any; endTime: any }) => {
                    const st = DateTime.fromISO(record.startTime);
                    const et = DateTime.fromISO(record.endTime);
                    return (
                      <div>
                        <HourglassTopOutlined /> {st.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
                        {et.toLocaleString(DateTime.TIME_SIMPLE)}
                      </div>
                    );
                  }}
                />
              }
              host={
                <FunctionField
                  className="flex justify-start h-50"
                  textAlign="left"
                  render={(record: { userinfo: any }) => {
                    return (
                      <div>
                        <EmojiPeopleOutlined /> {record.userinfo?.name}
                      </div>
                    );
                  }}
                />
              }
              rsvpLink={
                <FunctionField
                  className="flex justify-start"
                  textAlign="left"
                  render={(record: { rsvpLink: string }) => {
                    return (
                      record.rsvpLink && (
                        <div>
                          <OpenInNewOutlined />{" "}
                          <Link target="_blank" href={`https://${record.rsvpLink}`}>
                            {record.rsvpLink}
                          </Link>
                        </div>
                      )
                    );
                  }}
                />
              }
              websiteLink={
                <FunctionField
                  className="flex justify-start"
                  textAlign="left"
                  render={(record: { webLink: string }) => {
                    return (
                      record.webLink && (
                        <div>
                          <OpenInBrowserOutlined />{" "}
                          <Link target="_blank" href={`https://${record.webLink}`}>
                            {record.webLink}
                          </Link>
                        </div>
                      )
                    );
                  }}
                />
              }
              hasActions={true}
            />
          </div>

          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2">
            <SimpleShowLayout>
              <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="fullWidth" />}>
                <Tab label="Attendees">
                  <AttendeeList />
                </Tab>
              </TabbedShowLayout>
            </SimpleShowLayout>
          </div>
        </div>
      </Show>
    </>
  );
};
