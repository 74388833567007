import { IconButton, Menu, Button } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

type Params = {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  children: any;
  onClick: (event: any) => void;
};

export const DropdownButtons = (params: Params) => {
  const { children, anchorEl, open, onClose, onClick } = params;

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClick}
        className="min-w-[40px] max-h-[40px]"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export const DropdownActionButtons = (params: Params) => {
  const { children, anchorEl, open, onClose, onClick } = params;

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClick}
        className="min-w-[40px] max-h-[40px]"
        variant="contained"
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children}
      </Menu>
    </>
  );
};
