import { Admin, CustomRoutes, Resource, localStorageStore } from "react-admin";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ClubCreate, ClubEdit, ClubList, ClubShow } from "./page/clubs";
import { EventCreate, EventEdit, EventList, EventShow } from "./page/events";
import { GroupCreate, GroupEdit, GroupList, GroupShow } from "./page/groups";
import {
  Login,
  PasswordRecovery,
  Register,
  SetNewPassword,
  UserList,
  UserShow,
} from "./page/users";
import { theme, themeBondo } from "./theme";

import { AdminLayout } from "./layout/";
import { BannedUserList } from "./page/bannedUsers";
import { BroadCast } from "./page/adminTools";
import { Dashboard } from "./page/Dashboard";
import { ReportList } from "./page/report";
import { ReportedGroupList } from "./page/reportedGroups";
import { ReportedProfileList } from "./page/reportedProfiles";
import { authProvider } from "./authProvider";
import { customDataProvider } from "./dataProvider";

const store = localStorageStore();
store.setItem("sidebar.open", true);

const App = () => {
  const primaryTheme = localStorage.getItem("slug") === "chapman" ? theme : themeBondo;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/password-recovery" element={<PasswordRecovery theme={primaryTheme} />} />
        <Route path="/new-password" element={<SetNewPassword theme={primaryTheme} />} />
        <Route path="/register" element={<Register theme={primaryTheme} />} />
        <Route
          path="/*"
          element={
            <Admin
              dashboard={Dashboard}
              theme={primaryTheme}
              authProvider={authProvider}
              layout={AdminLayout}
              store={store}
              dataProvider={customDataProvider}
              requireAuth
              loginPage={Login}>
              {(permissions: string) => (
                <>
                  {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="events"
                      options={{ label: "Manage Events" }}
                      list={EventList}
                      create={EventCreate}
                      show={EventShow}
                      edit={EventEdit}
                    />
                  )}
                  {["clubAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="clubs"
                      options={{ label: "Manage Clubs" }}
                      list={ClubList}
                      create={ClubCreate}
                      show={ClubShow}
                      edit={ClubEdit}
                    />
                  )}
                  {["groupAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <>
                      <Resource
                        name="groups"
                        options={{ label: "Manage Groups" }}
                        list={GroupList}
                        create={GroupCreate}
                        show={GroupShow}
                        edit={GroupEdit}
                      />
                      <Resource
                        name="report/admin/posts"
                        options={{ label: "Posts" }}
                        list={ReportList}
                      />
                    </>
                  )}
                  {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="report/admin/profiles"
                      options={{ label: "Profiles" }}
                      list={ReportedProfileList}
                    />
                  )}
                  {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="report/admin/groups"
                      options={{ label: "Groups" }}
                      list={ReportedGroupList}
                    />
                  )}
                  {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="users"
                      options={{ label: "Manage Users" }}
                      list={UserList}
                      show={UserShow}
                    />
                  )}

                  {["superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="schools"
                      options={{ label: "Manage Schools" }}
                      list={UserList}
                      show={UserShow}
                    />
                  )}

                  {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <Resource
                      name="bannedUsers"
                      options={{ label: "Banned Users" }}
                      list={BannedUserList}
                    />
                  )}

                  {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && (
                    <CustomRoutes>
                      <Route
                        path="/adminTools/broadcast"
                        element={<BroadCast theme={primaryTheme} />}
                      />
                    </CustomRoutes>
                  )}
                </>
              )}
            </Admin>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
