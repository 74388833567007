import { ClubsRequest } from "./api/clubs";
import { CommonRequest } from "./api/common";
import { GroupsRequest } from "./api/groups";
import { PostRequest } from "./api/post";
import { ReportRequest } from "./api/report";
import { UserRequest } from "./api/user";

export const customDataProvider = {
  ...CommonRequest,
  ...UserRequest,
  ...ClubsRequest,
  ...GroupsRequest,
  ...ReportRequest,
  ...PostRequest,
};
