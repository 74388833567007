import { Card, CardContent, Typography } from "@mui/material";

import { EditButton } from "react-admin";

type ClubProps = {
  title: any;
  description: any;
  image: any;
  status: any;
  school: any;
  hasActions?: boolean;
};
export const ClubProfileCard = (props: ClubProps) => {
  const { title, description, school, image, status, hasActions } = props;
  return (
    <Card className="max-w-[282px] min-w-[182px] mt-[8px] mx-[16px] mb-[16px] rounded-[22px]">
      <div
        style={{
          width: "94px",
          height: "94px",
          borderRadius: "50%",
          margin: "auto",
          marginTop: "32px",
          background: "#A02727",
        }}>
        {image}
      </div>
      <div className="flex justify-center mt-8">{status}</div>
      <CardContent className="text-center">
        {title}
        <Typography>{description}</Typography>
        <Typography>{school}</Typography>
      </CardContent>
      {hasActions ? (
        <div className="flex justify-center mb-8">
          <EditButton label="Edit Profile" variant="contained" icon={<></>} size="large" />
        </div>
      ) : null}
    </Card>
  );
};

type GroupProps = {
  title: any;
  description: any;
  image: any;
  status: any;
  hasActions?: boolean;
};
export const GroupProfileCard = (props: GroupProps) => {
  const { title, description, image, status, hasActions } = props;
  return (
    <Card className="max-w-[282px] min-w-[182px] mt-[8px] mx-[16px] mb-[16px] rounded-[22px]">
      <div
        style={{
          width: "94px",
          height: "94px",
          borderRadius: "50%",
          margin: "auto",
          marginTop: "32px",
          background: "#A02727",
        }}>
        {image}
      </div>
      <CardContent className="text-center">
        {title}
        <Typography>{description}</Typography>
      </CardContent>
      <div className="flex justify-center mb-8">{status}</div>
      {hasActions ? (
        <div className="flex justify-center mb-8">
          <EditButton label="Edit Profile" variant="contained" icon={<></>} size="large" />
        </div>
      ) : null}
    </Card>
  );
};

type EditProps = {
  name: any;
  image: any;
  children: any;
};

export const ClubProfileEditCard = (props: EditProps) => {
  const { name, image, children } = props;
  return (
    <Card className="max-w-[282px] min-w-[182px] mt-[8px] mx-[16px] mb-[16px] rounded-[22px]">
      <div
        style={{
          width: "94px",
          height: "94px",
          borderRadius: "50%",
          margin: "auto",
          marginTop: "32px",
          background: "#A02727",
        }}>
        {image}
      </div>
      <CardContent className="text-center">
        {name}
        <Typography>Club</Typography>
      </CardContent>
      <div className="flex justify-center mb-8">{children}</div>
    </Card>
  );
};

type USerProfileProps = {
  name: any;
  image: any;
  status: any;
};

export const UserProfileCard = (props: USerProfileProps) => {
  const { name, image, status } = props;
  return (
    <Card className="max-w-[282px] min-w-[182px] mt-[8px] mx-[16px] mb-[16px] rounded-[22px]">
      <div
        style={{
          width: "94px",
          height: "94px",
          borderRadius: "50%",
          margin: "auto",
          marginTop: "32px",
          background: "#A02727",
        }}>
        {image}
      </div>
      <CardContent className="text-center">
        {name}
        <Typography>Student</Typography>
      </CardContent>
      <div className="flex justify-center mb-8">{status}</div>
    </Card>
  );
};

type EventsProps = {
  title: any;
  description: any;
  image: any;
  status: any;
  location: any;
  date: any;
  time: any;
  hasActions?: boolean;
  host: any;
  websiteLink?: any;
  rsvpLink?: any;
};
export const EventProfileCard = (props: EventsProps) => {
  const {
    title,
    description,
    image,
    status,
    hasActions,
    location,
    date,
    time,
    host,
    websiteLink,
    rsvpLink,
  } = props;
  return (
    <Card className="max-w-[282px] min-w-[182px] mt-[8px] mx-[16px] mb-[16px] rounded-[22px]">
      <div
        style={{
          width: "100%",
          height: "100%",
          marginTop: "32px",
        }}>
        {image}
      </div>
      <CardContent className="text-center">
        {title}
        <div className="flex justify-center my-4">{status}</div>
        <span className="d-flex flex-column align-items-left text-align-left">
          {websiteLink && websiteLink}
          {description}
          {location}
          {date}
          {time}
          {host}
          {rsvpLink && rsvpLink}
        </span>
      </CardContent>
      {hasActions ? (
        <div className="flex justify-center mb-8">
          <EditButton label="Edit Profile" variant="contained" icon={<></>} size="large" />
        </div>
      ) : null}
    </Card>
  );
};
