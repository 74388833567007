import { GroupInterface, GroupRequestInterface } from "../type/group";

import { instance } from "../helper/api";

export const GroupsRequest: GroupRequestInterface = {
  updatePresident: async (id: number, params: GroupInterface) => {
    const r = instance.put<GroupInterface>(`/club/president/${id}`, params);
    return await Promise.resolve({ data: r });
  },
  createMany: async (params: GroupInterface) => {
    console.log("createMany", params);
    const r = await instance.post(`/club/bulk/`, { data: params });
    return await Promise.resolve(r.data);
  },
};
