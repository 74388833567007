import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import { Box, Button, Chip, Typography } from "@mui/material";
import { Cell, Sheet } from "../../component/grid";
import { SetStateAction, useState } from "react";

import CSVReader from "react-csv-reader";
import { ContentHeader } from "../../component/header/content";
import { GridElement } from "../../type/grid";
import { HelpOutline } from "@mui/icons-material";
import { LightTooltip } from "../../component/tooltip";
import ReactDataSheet from "react-datasheet";
import { ToggleColorButton } from "../../component/button";
import { findIndex } from "lodash";
import { useMutation } from "react-query";
import { validateEmail } from "../../helper/string";

class ClubDataSheet extends ReactDataSheet<GridElement, string> {}

const initialRows: GridElement[][] = [
  [
    { value: "", name: "title" },
    { value: "", name: "email" },
    { value: "", name: "type" },
  ],
  [
    { value: "", name: "title" },
    { value: "", name: "email" },
    { value: "", name: "type" },
  ],
  [
    { value: "", name: "title" },
    { value: "", name: "email" },
    { value: "", name: "type" },
  ],
];

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
};

export const ClubCreate = () => {
  const dataProvider = useDataProvider();
  const [rows, setRows] = useState(initialRows);
  const [csv, setCsv] = useState([]);
  const [fileInfo, setFileInfo] = useState({ name: "" });
  const notify = useNotify();

  const createAnother = () => {
    setRows(
      rows.concat([
        [
          { value: "", name: "title" },
          { value: "", name: "email" },
          { value: "", name: "type" },
        ],
      ]),
    );
  };

  const handleFileDelete = () => {
    setFileInfo({ name: "" });
    setCsv([]);
  };

  const UploadCsvButton = () => {
    const { mutate, isLoading } = useMutation(["uploadManyClubs", csv], () => {
      return dataProvider.createMany(csv);
    });
    return (
      <div className="flex items-end gap-4 mb-4">
        {fileInfo.name ? (
          <Chip label={fileInfo.name} onDelete={handleFileDelete} size="medium" />
        ) : null}
        <Button variant="contained" type="button" onClick={() => mutate()} disabled={isLoading}>
          Click to upload
        </Button>
      </div>
    );
  };

  const onUploaded = (data: any, fileInfo: any) => {
    console.log("data", data);
    console.log("fileInfo", fileInfo);
    setCsv(data as SetStateAction<never[]>);
    setFileInfo(fileInfo);
  };

  const filterOutEmptyRow = (u: GridElement[]) => {
    return (
      u[findIndex(u, (o) => o.name === "title")].value ||
      u[findIndex(u, (o) => o.name === "email")].value ||
      u[findIndex(u, (o) => o.name === "type")].value
    );
  };

  const validateInput = (data: GridElement[][]) => {
    return data.filter(filterOutEmptyRow).map((r) => {
      const title = r[findIndex(r, (o) => o.name === "title")].value;
      const email = r[findIndex(r, (o) => o.name === "email")].value;
      const type = r[findIndex(r, (o) => o.name === "type")].value;

      if (!title || !email || !type) {
        const message = `All fields are required`;
        notify(message, { type: "error" });
        throw new Error(message);
      }

      const isValidEmail = validateEmail(email);
      if (!isValidEmail) {
        const message = `${email} is not a valid email`;
        notify(message, { type: "error" });
        throw new Error(message);
      }

      return {
        title,
        email,
        type,
        bio: "",
        imageUrl: "",
        insta: "",
        linkedin: "",
        members: [],
        tags: [],
        tiktok: "",
      };
    });
  };

  const PublishAllButton = () => {
    const isEmpty = rows.filter(filterOutEmptyRow).length === 0;
    const { mutate, isLoading, isSuccess } = useMutation(["createManyClubs", rows], () => {
      const validatedRows = validateInput(rows);
      return dataProvider.createMany(validatedRows);
    });

    return (
      <Button
        type="button"
        onClick={() => mutate()}
        disabled={isEmpty || isLoading}
        variant="contained">
        Publish all
      </Button>
    );
  };

  type FiledType = { cell: GridElement | null; row: number; col: number; value: string | null };

  const onFieldChange = (
    changes: ReactDataSheet.CellsChangedArgs<GridElement, string> | undefined,
  ) => {
    const grid = rows.map((row) => [...row]);
    changes?.forEach(({ row, col, value }: FiledType) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    setRows(grid);
  };

  const transform = async (inputData: any) => {
    const { pictures } = inputData;
    const { data } = await dataProvider.uploadImage(pictures);
    const { success, url } = data;
    let payload;
    if (success) {
      payload = {
        title: inputData.title,
        description: inputData.description,
        color: inputData.color,
        imageUrl: url,
        type: "private",
      };
    }
    return {
      ...payload,
    };
  };

  return (
    <>
      <ContentHeader subTitle="Create Club" />
      <div className="flex w-full mb-4">
        {csv.length ? (
          <>
            {/* <div className="w-full">
              <UploadCsvButton />
              <ClubDataSheet
                data={rows}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={onFieldChange}
                cellRenderer={Cell}
                sheetRenderer={Sheet}
              />
            </div> */}
          </>
        ) : (
          <div className="d-flex flex-column justify-start w-full">
            {/* <div className="flex w-full">
              <div className="mb-4">
                <CSVReader
                  cssClass="react-csv-input"
                  cssInputClass="csv-input"
                  cssLabelClass="csv-label"
                  label="Upload CSV"
                  onFileLoaded={onUploaded}
                  parserOptions={papaparseOptions}
                />
              </div>
              <LightTooltip title="Required on header are title, email and type.">
                <HelpOutline color="disabled" fontSize="medium" />
              </LightTooltip>
            </div> */}
            <Create transform={transform}>
              <SimpleForm>
                <ImageField source="imageUrl" title="title" />
                <ImageInput
                  source="pictures"
                  label="Club Image"
                  isRequired={true}
                  multiple={false}
                  accept="image/*"
                  maxSize={10000000} //10MB
                  sx={{
                    "& .RaFileInput-dropZone": {
                      backgroundColor: "gray",
                      width: "65%",
                    },
                    "& .RaLabeled-label": {
                      marginLeft: "2px",
                      fontSize: "15px",
                    },
                  }}>
                  <ImageField source="src" title="title" />
                </ImageInput>
                {/* <BooleanInput label="Private" source="type" /> */}
                <TextInput label="Name" source="title" required />
                <TextInput label="Description" multiline source="description" required />
                <ToggleColorButton source="color" />
                {/* <RadioButtonGroupInput
                  source="color"
                  choices={[
                    { id: "#3618A8", color: "#3618A8" },
                    { id: "#C41DFF", color: "#C41DFF" },
                    { id: "#034B20", color: "#034B20" },
                    { id: "#4090C9", color: "#4090C9" },
                    { id: "#000000", color: "#000000" },
                    { id: "#731CA3", color: "#731CA3" },
                    { id: "#A70032", color: "#A70032" },
                    { id: "#E9412B", color: "#E9412B" },
                    { id: "#EA602C", color: "#EA602C" },
                    { id: "#FFB702", color: "#FFB702" },
                    { id: "#ED9E38", color: "#ED9E38" },
                  ]}
                  optionText="color"
                /> */}
              </SimpleForm>
            </Create>
          </div>
        )}
      </div>
    </>
  );
};
