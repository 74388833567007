import { useForm, FormProvider } from "react-hook-form";
import { Modal, Box, Button, Typography } from "@mui/material";
import { SelectInput, DateInput } from "react-admin";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "12px",
  p: 4,
};

type Props = {
  open: boolean;
  onClose?: () => void;
  onSubmit: (values: any) => void;
  options: any;
};

const isActiveOptions = [
  { id: false, name: "Paused" },
  { id: true, name: "Active" },
];

const typeOptions = [
  { id: "club", name: "Club" },
  { id: "organization", name: "Organization" },
];

export const ClubFilterFormModal = (props: Props) => {
  const { open, onClose, options, onSubmit } = props;
  const { filterValues, setFilters, hideFilter } = options;

  const form = useForm({
    defaultValues: filterValues,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center">
              <div className="flex gap-4">
                <SelectInput
                  variant="outlined"
                  source="isActive"
                  label="Status"
                  choices={isActiveOptions}
                />
                <SelectInput variant="outlined" source="type" label="Type" choices={typeOptions} />
              </div>
              <Typography>Date Created</Typography>
              <div className="flex gap-4">
                <DateInput variant="outlined" source="createdFrom" label="From" />
                <DateInput variant="outlined" source="createdTo" label="To" />
              </div>
              <div className="flex justify-center gap-4">
                <Button variant="contained" color="primary" type="submit">
                  Filter
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export const UserFilterFormModal = (props: Props) => {
  const { open, onClose, options, onSubmit } = props;
  const { filterValues, setFilters, hideFilter } = options;

  const form = useForm({
    defaultValues: filterValues,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex justify-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center">
              <div className="flex gap-4">
                <SelectInput
                  variant="outlined"
                  source="isActive"
                  label="Status"
                  choices={isActiveOptions}
                />
              </div>
              <Typography>Date Created</Typography>
              <div className="flex gap-4">
                <DateInput variant="outlined" source="createdFrom" label="From" />
                <DateInput variant="outlined" source="createdTo" label="To" />
              </div>
              <div className="flex justify-center gap-4">
                <Button variant="contained" color="primary" type="submit">
                  Filter
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};
